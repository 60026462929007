.clinicPage {
	display: flex;
	width: 100vw;
	height: 100%;
	justify-content: center;
	// align-items: flex-end;

	&__mobile {
		display: none;
	}

	&__Center {
		// border: 1px solid black;
		display: flex;
		height: 70vh;
		width: 70vw;
		margin-bottom: 10vh;
		border-radius: 10px;
		background-color: rgb(254, 254, 254);
		margin-top: 170px;
	}
	&__Left {
		width: 40%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		align-items: center;
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;

		&-highlightButton {
			height: calc(60vh / 7);
			width: calc(60vw * 0.3 * 0.8);
			border: none;
			font-family: quicksand-med;
			font-style: normal;
			font-weight: 500;
			font-size: 24px;
			line-height: 30px;
			/* identical to box height */
			background: #82cecc;
			border-radius: 10px;
			text-align: center;
			color: white;
			&:focus {
				outline: none;
			}
			&:hover {
				color: black;
			}
		}
		&-normalButton {
			height: calc(60vh / 7);
			width: calc(60vw * 0.3 * 0.8);
			border: none;
			font-family: quicksand-med;
			font-style: normal;
			font-weight: 500;
			font-size: 24px;
			line-height: 30px;
			/* identical to box height */

			text-align: center;

			color: #82cecc;
			background-color: white;

			&:focus {
				outline: none;
			}
			&:hover {
				color: black;
			}
		}
	}
	&__Right {
		width: 70%;
	}
}
@include tablet {
	.clinicPage {
		&__Center {
			width: 80vw;
		}
	}
}

@include mobile {
	.clinicPage {
		&__Center {
			width: 100vw;
			height: 80%;
			display: flex;
			flex-direction: column;
			margin-top: 170px;
		}
		&__mobile {
			display: flex;
			padding: 5px 20px;
			&__shadow {
				width: 100vw;
				height: 100vh;
				position: absolute;
				top: 0;
				z-index: 3;
				background-color: rgba(0, 0, 0, 0.8);
				display: flex;
				justify-content: center;
				align-items: center;
				&-inner {
					height: 90vh;
					display: flex;
					flex-direction: column;
					// justify-content: space-evenly;
					& button {
						margin: 20px 0;
						width: 70vw;
						height: 50px;
						border-radius: 10px;
						background-color: white;
						transition: 1s all ease-in-out;
					}
					& img {
						margin-left: auto;
						display: block;
						width: 30px;
					}
				}
			}
		}
		&__Left {
			display: none;
			// width: 100%;
			// flex-direction: row;
			// height: 70px;
			// flex-wrap: wrap;
			// & button {
			// 	width: 100%;
			// 	height: 20px;
			// }
		}
		&__Right {
			width: 100%;
			// margin-top: 20px;
		}
	}
}
