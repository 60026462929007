.referral {
	width: 100vw;
	// height: 150vh;
	display: flex;
	justify-content: center;
	// align-items: center;
	&__center {
		width: 70vw;
		margin: 120px 0 50px 0;
		padding: 30px;
		height: 100%;
		&-header {
			text-transform: capitalize;
			font-family: quicksand-bold;
			font-style: normal;
			font-weight: bold;
			font-size: 36px;
			line-height: 45px;
			color: #82cecc;
			margin-bottom: 20px;
		}

		& ol {
			& li {
				margin: 10px 0;
				font-family: quicksand-reg;
				font-style: normal;
				// font-weight: bold;
				font-size: 25px;
				line-height: 35px;
			}
		}
		& div {
			font-family: quicksand-reg;
			font-style: normal;
			// font-weight: bold;
			font-size: 22px;
			line-height: 35px;
		}
	}
}

@include extraLarge {
	.referral {
		height: 100vh;
		&__center {
			// padding-bottom: 200px;
			height: 80%;
		}
	}
}
@include tablet {
	.referral {
		&__center {
			width: 80vw;
		}
	}
}

@include mobile {
	.referral {
		&__center {
			width: 90vw;
			&-header {
				font-size: 23px;
			}
			& ol {
				& li {
					font-size: 20px;
				}
			}
			& div {
				font-size: 18px;
			}
		}
	}
}
