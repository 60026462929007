@import './partials/breakpoints';
@import './partials/fonts';

@import './partials/header';
@import './partials/home';
@import './partials/home2';
@import './partials/patientToolPartial/form';
@import './partials/agreement';
@import './partials/bookingAgreement';
@import './partials/theClinic';
@import './partials/clinicPagePartial/clinicService';
@import './partials/clinicPagePartial/clinicDoctor';
@import './partials/clinicPagePartial/clinicPolicy';
@import './partials/clinicPagePartial/clinicFee';
@import './partials/schedule';
@import './partials/contact';
@import './partials/patientToolPartial/faq';
@import './partials/patientToolPartial/epayment';
@import './partials/patientToolPartial/guardme';
@import './partials/patientToolPartial/privatepatient';
@import './partials/resourcesInformationPartial/uninsured';
@import './partials/resourcesInformationPartial/testResult';
@import './partials/resourcesInformationPartial/referal';
@import './partials/resourcesInformationPartial/chartTransfer';
@import './partials/resourcesInformationPartial/insured';
@import './partials/resourcesInformationPartial/renewal';
@import './partials/clinicPagePartial/clinicVirtual';
@import './partials/newOldPatient/newOldFamily';
@import './partials/patientToolPartial/bookingInstruction';
@import './partials/news';
@import './partials/admin';
@import './partials/news/lotusNews';
@import './partials/news/qrcodeNews';

html {
	margin: 0;
	padding: 0;
	width: 100vw;
	overflow-x: hidden;
}
body {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	width: 100vw;
	// overflow: hidden;
	overflow-x: hidden;
}
.boldFont {
	font-family: quicksand-bold;
	font-style: normal;
	font-weight: bold;
	font-size: 28px;
	line-height: 30px;
}
.regFont {
	font-family: quicksand-reg;
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 30px;
	text-align: center;
}
