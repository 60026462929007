.uninsured {
	display: flex;
	width: 100vw;
	height: 100%;
	justify-content: center;
	flex-direction: column;
	align-items: center;

	&-basedOn {
		width: 60vw;
		margin-bottom: 30px;
		font-family: quicksand-med;
		font-style: normal;
		font-weight: bold;
		font-size: 22px;
		line-height: 45px;
	}
	&-header {
		margin: 120px 0 50px 0;
		font-family: quicksand-bold;
		font-style: normal;
		font-weight: bold;
		font-size: 36px;
		line-height: 45px;
		color: #82cecc;
	}
	&__table {
		border-spacing: 0;
		width: 70vw;
		border: 1px solid rgb(200, 200, 200);
		border-radius: 10px;
		margin-bottom: 50px;

		&-row {
			height: 50px;
		}

		&-price {
			border: 1px solid rgb(200, 200, 200);
			padding: 5px;
			font-family: quicksand-reg;
			font-style: normal;
			// font-weight: bold;
			font-size: 20px;
			line-height: 35px;
		}
		&-name {
			border: 1px solid rgb(200, 200, 200);
			padding: 5px;
			font-family: quicksand-reg;
			font-style: normal;
			// font-weight: bold;
			font-size: 20px;
			line-height: 35px;
		}
	}
}

@include mobile {
	.uninsured {
		&-basedOn {
			width: 80vw;
		}
		&__table {
			width: 95vw;
		}
	}
}
