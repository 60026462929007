.testResult {
	width: 100vw;
	height: 100%;
	display: flex;
	// flex-direction: column;
	justify-content: center;
	align-items: center;

	&__center {
		width: 70vw;
		height: 100%;

		padding: 30px;
		margin: 120px 0 50px 0;

		&-header {
			text-transform: capitalize;
			font-family: quicksand-bold;
			font-style: normal;
			font-weight: bold;
			font-size: 36px;
			line-height: 45px;
			color: #82cecc;
		}
		& div {
			margin: 20px 0;
			font-family: quicksand-reg;
			font-style: normal;
			// font-weight: bold;
			font-size: 25px;
			line-height: 35px;
			& h4 {
				font-family: quicksand-reg;
				font-style: normal;
				// font-weight: bold;
				font-size: 25px;
				line-height: 35px;
			}
		}
	}
}

@include tablet {
	.testResult {
		height: 100%;
		&__center {
			margin: 120px 0 50px 0;
		}
	}
}

@include mobile {
	.testResult {
		height: 100%;
		&__center {
			margin: 100px 0 50px 0;
			width: 90vw;
			&-header {
				font-size: 25px;
			}
		}
		& div {
			font-size: 20px;
			& h4 {
				font-size: 25px;
				font-weight: 550;
				margin-bottom: 20px;
			}
		}
	}
}
