.epay {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;

	&__center {
		border-radius: 10px;
		padding: 50px;
		margin-top: 120px;
		width: 60vw;

		&-header {
			text-transform: capitalize;
			font-family: quicksand-bold;
			font-style: normal;
			font-weight: bold;
			font-size: 36px;
			line-height: 45px;
			color: #82cecc;
			margin: 0 0 50px 0;
		}
	}
	&__textDiv {
		& h4 {
			margin: 10px 0;
			padding-left: 2vw;
			font-family: quicksand-reg;
			font-style: normal;
			// font-weight: bold;
			font-size: 25px;
			line-height: 35px;
		}
	}
}

@include tablet {
	.epay {
		&__center {
			margin: 120px 0 50px 0;
			width: 80vw;
			&-header {
				margin-bottom: 20px;
			}
			& h4 {
				font-size: 20px;
			}
		}
	}
}

@include mobile {
	.epay {
		height: 100vh;
		&__center {
			width: 85vw;
			// height: 100%;
			margin: 180px 0 100px 0;
			&-header {
				margin-bottom: 17px;
			}
			& h4 {
				font-size: 18px;
				margin: 20px 0;
			}
		}
	}
}
