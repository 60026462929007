.virtual {
	padding: 20px 30px 0 30px;
	&__bigTextDiv {
		overflow-x: hidden;
		overflow-x: auto;
		text-align: justify;
		height: 55vh;

		&-header {
			text-transform: capitalize;
			text-transform: capitalize;
			font-family: quicksand-bold;
			font-style: normal;
			font-weight: normal;
			font-size: 35px;
			line-height: 30px;
			margin-bottom: 30px;
		}
		&-firstDiv {
			padding: 0 30px 0 0;
			text-indent: 20px;
			& h4 {
				text-transform: capitalize;
				font-family: quicksand-med;
				font-style: normal;
				font-weight: normal;
				font-size: 20px;
				line-height: 30px;
			}
			& section {
				text-indent: 0;
				text-transform: capitalize;
				font-family: quicksand-reg;
				font-style: normal;
				font-weight: normal;
				font-size: 18px;
				line-height: 30px;
			}
			& ol {
				& li {
					text-transform: capitalize;
					font-family: quicksand-reg;
					font-style: normal;
					font-weight: normal;
					font-size: 17px;
					line-height: 30px;
				}
			}
		}
	}
}
@include mobile {
	.virtual {
		height: 70vh;
		& h3 {
			font-size: 25px;
		}
	}
}
