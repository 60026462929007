.guardme {
	padding: 100px 0;
	width: 100vw;

	&-header {
		width: 100vw;
		text-align: center;
		height: 50px;
		margin-top: 100px;
		font-family: quicksand-bold;
		font-style: normal;
		font-weight: bold;
		font-size: 36px;
		line-height: 45px;
		color: #82cecc;
	}

	&-header1 {
		margin: 20px 0 50px 0;
		text-align: center;
		font-family: quicksand-bold;
		font-style: normal;
		font-weight: bold;
		font-size: 36px;
		line-height: 45px;
		color: #82cecc;
	}
	&-footer {
		text-decoration: none;
		width: 150px;
		margin: 0 auto;
		display: block;

		border-radius: 10px;
		text-align: center;
		padding: 10px 0;
		color: white;
		font-family: quicksand-reg;
		font-style: normal;
		// font-weight: bold;
		font-size: 25px;
		line-height: 35px;
		background-color: #82cecc;
	}
	&__center {
		display: flex;
		width: 100vw;
		justify-content: space-around;
		&-img {
			height: 200px;
			width: 200px;
		}
		&__leftRightDiv {
			width: 30vw;
			display: flex;
			flex-direction: column;
			align-items: center;

			&-missingDiv {
				height: 70px;
			}

			& h3 {
				margin: 50px 0;
				font-family: quicksand-bold;
				font-style: normal;
				// font-weight: bold;
				font-size: 25px;
				line-height: 35px;
			}
			& h4 {
				margin: 20px 0;
				font-family: quicksand-reg;
				font-style: normal;
				// font-weight: bold;
				font-size: 25px;
				line-height: 35px;
			}
			& h5 {
				margin: 20px 0;
				font-family: quicksand-reg;
				font-style: normal;
				// font-weight: bold;
				font-size: 25px;
				line-height: 35px;
			}
			& ul {
				& li {
					margin: 15px 0;
					font-family: quicksand-reg;
					font-style: normal;
					// font-weight: bold;
					font-size: 25px;
					line-height: 35px;
				}
			}
		}
	}
}
@include mobile {
	.guardme {
		&-header1 {
			margin: 0 50px 30px;
		}
		&__center {
			&-img {
				height: 100px;
				width: 100px;
			}
			&__leftRightDiv {
				&-missingDiv {
					height: 200px;
				}
				& h3 {
					height: 100px;
				}
			}
		}
	}
}
