@font-face {
	font-family: 'quicksand-reg';
	src: url(../assets/fonts/Quicksand/static/Quicksand-Regular.ttf);
}
@font-face {
	font-family: 'quicksand-med';
	src: url(../assets/fonts/Quicksand/static/Quicksand-Medium.ttf);
}
@font-face {
	font-family: 'quicksand-bold';
	src: url(../assets/fonts/Quicksand/static/Quicksand-Bold.ttf);
}
