.private {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;

	&-header {
		margin-bottom: 20px;
		font-family: quicksand-bold;
		font-style: normal;
		font-weight: bold;
		font-size: 36px;
		line-height: 45px;
		color: #82cecc;
	}
	&__textDiv {
		width: 70vw;
		// height: 100%;
		border-radius: 10px;
		padding: 20px;
		margin: 120px 0 50px 0;
		& h4 {
			font-family: quicksand-med;
			font-style: normal;
			// font-weight: bold;
			font-size: 25px;
			line-height: 35px;
		}
		& ul {
			& li {
				font-family: quicksand-reg;
				font-style: normal;
				// font-weight: bold;
				font-size: 25px;
				line-height: 35px;
			}
		}
	}
	&__textDiv1 {
		// height: 100%;

		padding: 20px;
		margin: 120px 0 50px 0;
		// width: 80vw;
		& h4 {
			font-size: 25px;
			margin: 10px 0;
		}
		& ul {
			& li {
				font-size: 20px;
				margin: 10px 0;
			}
		}
		& ol {
			& li {
				margin: 10px 0;
			}
		}
	}
}
.private1 {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
}

@include tablet {
	.private {
		height: 100%;
	}
}

@include mobile {
	.private {
		height: 100%;
		&__textDiv {
			width: 85vw;
			& h4 {
				font-size: 20px;
			}
			& ul {
				& li {
					font-size: 18px;
				}
			}
		}
		&__textDiv1 {
			& h4 {
				font-size: 20px;
			}
			& ul {
				& li {
					font-size: 18px;
				}
			}
		}
	}
}

@include extraLarge {
	.private {
		height: 100vh;
	}
}
