.slideShow {
	height: 500px;
	width: 100vw;
	&__slides {
		height: 500px;
		width: 100vw;
		&-1 {
			background-image: url(../assets/downloadImages/rsz_mainpage1.png);
			// background-attachment: fixed;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			height: 100%;
			width: 100vw;
		}
		&-2 {
			background-image: url(../assets/downloadImages/rsz_mainpage2.png);
			// background-attachment: fixed;
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			height: 100%;
			width: 100vw;
		}
		&-3 {
			// background-image: url(../assets/NewsBanner/Jvon-banner_1440x576.png);
			background-image: url(../assets/NewsBanner/WechatIMG2.png);
			// background-attachment: fixed;
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			height: 100%;
			width: 100vw;
		}
		&-4 {
			// background-image: url(../assets/NewsBanner/Jvon-banner_1440x576.png);
			background-image: url(../assets/downloadImages/rsz_1mainpage3.png);
			// background-attachment: fixed;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			height: 100%;
			width: 100vw;
		}
	}
}

.react-slideshow-container .nav:last-of-type {
	margin-left: -60px !important;
	z-index: 1 !important;
}
.react-slideshow-container .nav:first-of-type {
	z-index: 1 !important;
}
.something {
	// background-image: url(../assets/clinicBackgroundPic/mainPageBackground3.jpg);
	// background-attachment: fixed;
	// background-position: center;
	// background-repeat: no-repeat;
	// background-size: cover;
	height: 100%;
	width: 100vw;
	// background-color: rgba(116, 229, 210, 0.3);
}

.clinicBooking {
	display: flex;
	justify-content: center;
	align-items: center;
	&-header {
		margin: 60px 0 18px 80px;
		font-family: quicksand-med;
		font-style: normal;
		font-weight: 500;
		font-size: 36px;
		line-height: 45px;
	}
	& a {
		color: black;
		text-decoration: none;
		border: 1px solid black;
		text-transform: capitalize;
		width: 360px;
		margin: 20px auto;
		height: 100px;
		transition: ease-in-out 0.2s all;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: row;
		// background: #ffffff;
		box-shadow: 3px 5px 15px rgba(0, 0, 0, 0.07);
		border-radius: 10px;
		border: none;
		font-family: quicksand-med;
		font-style: normal;
		font-weight: 500;
		font-size: 24px;
		line-height: 30px;

		&:hover {
			transform: scale(1.05);
		}
	}
	&__ButtonDiv {
		&-familyLink {
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			background-image: url(../assets/icons/icon-family_doctor.png);
		}
		&-walkInLink {
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			background-image: url(../assets/icons/icon-walk_in.png);
		}
		&-virtualLink {
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			background-image: url(../assets/icons/icon-book_virtual.png);
		}
	}
}

.clinicLocation {
	&-header {
		margin: 80px 0 70px 80px;
	}
	&__firstDiv {
		display: flex;
		padding: 0 80px;
		// justify-content: space-between;
		&__location {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 350px;
			&-header {
				font-family: quicksand-bold;
				font-style: normal;
				font-weight: bold;
				font-size: 24px;
				line-height: 30px;
				text-align: center;
				margin-top: 30px;
			}
			&-address {
				font-family: quicksand-reg;
				font-style: normal;
				font-weight: bold;
				font-size: 24px;
				line-height: 30px;
				text-align: center;
				margin: 20px 0;
			}
			&-phoneDiv {
				& h4 {
					font-family: quicksand-reg;
					font-style: normal;
					font-weight: bold;
					font-size: 24px;
					line-height: 30px;
					text-align: center;
				}
			}
		}
		&__doctorDiv {
			display: flex;
			align-items: center;
			&-section {
				margin-left: 70px;
				& img {
					height: 100px;
					width: 100px;
					margin-bottom: 30px;
				}
				& h4 {
					font-family: quicksand-reg;
					font-style: normal;
					font-weight: normal;
					font-size: 24px;
					line-height: 30px;
					text-align: center;
				}
			}
		}
	}
	&-secondDiv {
		margin: 150px 0;
	}
}

.emptyHeaderDiv {
	display: block;
	height: 100px;
}

.backgroundChanger {
	height: 500px;
	width: 100vw;
	animation: backgroundChanger 20s infinite normal;
}

.clinicNews {
	&-header {
		font-family: quicksand-med;
		font-style: normal;
		font-weight: 500;
		font-size: 36px;
		line-height: 45px;

		margin: 65px 0 0 80px;
	}
	& a {
		margin-left: 80px;
		font-family: quicksand-reg;
		font-style: normal;
		font-weight: 500;
		font-size: 22px;
		line-height: 30px;
		// margin-top: 20px;
		/* identical to box height */

		color: #82cecc;
		& h5 {
			font-family: quicksand-reg;
			font-style: normal;
			font-weight: normal;
			font-size: 22px;
			line-height: 30px;
			color: black;
			border-bottom: 2px solid #e4e4e4;
			padding-bottom: 23px;
			// margin-top: 20px;
			margin-left: 80px;
		}
	}
	& section {
		margin-top: 15px;
	}
}

@keyframes backgroundChanger {
	0% {
		background: url('../assets/downloadImages/mainPage1.jpeg');
		// background-attachment: fixed;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}
	50% {
		background: url('../assets/downloadImages/mainPage2.jpeg');
		// background-attachment: fixed;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}
	100% {
		background: url('../assets/downloadImages/mainPage1.jpeg');
		// background-attachment: fixed;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}
}

@include tablet {
	.clinicLocation {
		&-header {
			height: 30px;
			margin: 10px 0;
		}
		&-achor {
			margin-left: 10px;
			font-size: 10px;
		}
		&-Text1 {
			font-size: 13px;
		}
		&-Text2 {
			font-size: 10px;
		}

		&-body1 {
			height: 150px;
			padding-bottom: 5px;
			h1,
			h2,
			h3,
			h4 {
				margin: 0 0 0 10px;
			}
		}
		&-numberDiv {
			flex-direction: column;
			height: 20px;
			justify-content: space-between;
		}
		&__doctorDiv {
			flex-flow: wrap;
			height: 60px;
			margin: 10px 0 0 0;
			&__section {
				height: 30px;
				width: 60px;
				margin: 3px 0;
			}
		}
	}
}

@include mobile {
	// .firstSection {
	// 	display: flex;
	// 	flex-direction: column;
	// 	align-items: center;
	// 	// margin-top: 20px;
	// 	// padding-top: 20px;
	// }
	.clinicLocation {
		width: 100vw;
		margin-bottom: 100px;
		&__firstDiv {
			padding: 0;
			flex-direction: column;
			&__location {
				width: 100vw;
			}
			&__doctorDiv {
				// flex-direction: column;
				margin-top: 50px;
				flex-wrap: wrap;
				justify-content: center;
				&-section {
					margin: 10px 20px;
				}
			}
		}
		&-secondDiv {
			margin: 50px 0 0 0;
		}
	}
	.clinicBooking {
		// width: 90vw;
		// margin: 20px 0;
		// height: 370px;
		display: flex;
		flex-direction: column;
		&-header {
			font-size: 30px;
			line-height: 22px;
			margin: 60px 0 0 15px;
			text-align: center;
		}
	}
	.clinicNews {
		&-header {
			text-align: center;
			margin-left: 0;
		}
		& a {
			& h5 {
				margin: 0 10px;
				text-align: center;
			}
		}
		& section {
			text-align: center;
			& a {
				margin: 0;
			}
		}
	}
	.backgroundChanger {
		width: 100vw;
		height: 200px;
		background-attachment: fixed;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}
	.emptyHeaderDiv {
		height: 70px;
	}
	.slideShow {
		height: 190px;
		width: 100vw;
		&__slides {
			height: 190px;
			width: 100vw;
			&-1 {
				background-image: url(../assets/downloadImages/mobileMain1.png);
			}
			&-2 {
				background-image: url(../assets/downloadImages/mobileMain2.png);
				height: 100%;
				background-size: cover;
			}
			&-3 {
				background-image: url(../assets/NewsBanner/WechatIMG1.png);
				background-size: contain;
			}
		}
	}
	.react-slideshow-container .nav:last-of-type {
		margin-left: -30px !important;
	}
	.react-slideshow-container .nav:first-of-type {
		padding-left: 0px !important;
	}
	.react-slideshow-container .nav {
		background: none !important;
	}
}
