.clinicFeeGuide {
	font-family: "Times New Roman", Times, serif;

	overflow-x: hidden;
	overflow-x: auto;
	text-align: justify;
	height: 70vh;
	padding: 20px 30px 0 30px;
	&-header {
		margin-bottom: 30px;
		text-transform: capitalize;
		text-transform: capitalize;
		font-family: quicksand-bold;
		font-style: normal;
		font-weight: normal;
		font-size: 35px;
		line-height: 30px;
	}
	&-textDiv {
		text-transform: capitalize;
		font-family: quicksand-reg;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		line-height: 30px;
	}
	&-explain {
		width: 90%;
		margin-bottom: 30px;
		font-family: quicksand-reg;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		line-height: 30px;
	}
}
@include mobile {
	.clinicFeeGuide {
		&-header {
			font-size: 25px;
			margin-bottom: 15px;
		}
		&-textDiv {
			font-size: 20px;
		}
	}
}
