.lotusNews {
	width: 100vw;
	height: 100%;

	&__center {
		padding: 170px 20px 0 20px;
		&-header {
			text-align: center;
		}
		&-img {
			height: 500px;
			width: 70vw;
			margin: 30px auto;
			display: block;
		}
		&-do {
			margin-left: 30px;
		}
		&__textDiv {
			& h4 {
				font-family: quicksand-reg;
				font-style: normal;
				font-weight: normal;
				font-size: 24px;
				line-height: 30px;
				// text-align: center;
			}
		}
		& ul {
			& li {
				font-family: quicksand-reg;
				font-style: normal;
				font-weight: normal;
				font-size: 22px;
				line-height: 30px;
				// text-align: center;
			}
		}
		&-bottomText {
			font-family: quicksand-med;
			font-style: normal;
			font-weight: normal;
			font-size: 24px;
			line-height: 30px;
			text-align: center;
		}
		&-bottomDiv {
			& h4 {
				font-family: quicksand-med;
				font-style: normal;
				font-weight: normal;
				font-size: 24px;
				line-height: 30px;
				text-align: center;
				margin-bottom: 100px;
			}
		}
	}
}
