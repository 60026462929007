.allNews {
	width: 100vw;
	height: 100vh;
	&__emptyDiv {
		height: 140px;
	}
	&__banner {
		height: 500px;

		&__content {
			height: 500px;
			&-1 {
				background-image: url('../assets/NewsBanner/rsz_jvon-banner_1440x576.png');
				// background-attachment: fixed;
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
				height: 100%;
				width: 100vw;
			}
			&-2 {
				background-image: url('../assets/NewsBanner/WechatIMG.jpeg');
				// background-attachment: fixed;
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
				height: 100%;
				width: 100vw;
			}
		}
	}
}
.allNewsWork {
	width: 100vw;
	height: 100vh;

	&-bannerDiv {
		height: 500px;
		// margin-top: 140px;

		background-image: url('../assets/NewsBanner/rsz_jvon-banner_1440x576.png');
		// background-attachment: fixed;
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
	}

	&__Div {
		margin: 20px 0;
		padding: 20px;
		& h3 {
			font-weight: 800;
			color: #808e94;
		}

		& h4 {
			text-indent: 20px;
			font-weight: 300;
			margin-top: 20px;
			padding-bottom: 25px;
			border-bottom: 1px solid #808e94;
		}
	}
}

@include mobile {
	.allNews {
		&__emptyDiv {
			height: 70px;
		}
		&__banner {
			height: 200px;

			&__content {
				height: 200px;
				&-1 {
					background-image: url(../assets/NewsBanner/thumbnail_Jvon-banner_450x220.png);
				}
				&-2 {
					background-image: url('../assets/NewsBanner/WechatIMG.jpeg');
				}
			}
		}
	}
	.allNewsWork {
		&-bannerDiv {
			height: 200px;
			background-image: url(../assets/NewsBanner/thumbnail_Jvon-banner_450x220.png);
		}
	}
}
