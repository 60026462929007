@mixin tablet {
	@media only screen and (min-width: 768px) and (max-width: 1300px) {
		@content;
	}
}

@mixin desktop {
	@media only screen and (min-width: 1301px) {
		@content;
	}
}

@mixin mobile {
	@media only screen and (max-width: 767px) {
		@content;
	}
}

@mixin extraLarge {
	@media only screen and (min-width: 1500px) {
		@content;
	}
}
