.bookingIns {
	display: flex;
	// flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;

	&-virtualDiv {
		// margin-top: 30px;
		// display: flex;
		// width: 100vw;
		align-items: center;
		justify-content: space-evenly;
		font-family: quicksand-bold;
		font-style: normal;
		font-weight: bold;
		font-size: 36px;
		line-height: 45px;
		color: #82cecc;
		text-align: center;
	}
	&-video {
		width: 600px;
		height: 300px;
		border: 1px solid black;
		margin: 20px 0;
	}
}

@include mobile {
	.bookingIns {
		&-video {
			width: 300px;
			height: 200px;
			border: 1px solid black;
		}
	}
}
