.bookagreeDiv {
	height: 300vh;
	width: 100vw;
	background-color: rgba(0, 0, 0, 0.6);
	position: absolute;
	// margin-top: -10vh;
	justify-content: center;
	align-items: flex-start;
	z-index: 2;
	&__textDiv {
		background-color: white;
		width: 60vw;
		margin-top: 10vh;
		padding: 30px;
		border-radius: 10px;
		& h5 {
			font-weight: 300;
		}
		& li {
			margin-top: 8px;
		}
		& h6 {
			font-size: 17px;
			display: block;
			margin-left: auto;
			width: 425px;
		}
		& button {
			display: block;
			width: 100px;
			margin: 10px auto 0;
			height: 30px;
			&:hover {
				cursor: pointer;
			}
		}
	}
	&__emergencyDiv {
		display: flex;
		flex-direction: column;
		align-items: center;
		& h3 {
			color: red;
			font-size: 25px;
			margin-bottom: 10px;
		}
		& h4 {
			font-weight: 500;
			color: red;
			line-height: 30px;
		}
	}
	& a {
		text-transform: capitalize;
	}
}

.bookingFamilyNewOrOld {
	display: flex;
	align-items: center;
	flex-direction: column;
	height: 100vh;
	&-header {
		margin: 170px 0 0 0;

		color: #82cecc;
		font-family: quicksand-bold;
		font-style: normal;
		font-weight: bold;
		font-size: 36px;
		line-height: 45px;
	}
	&-subHeader {
		margin: 20px 0 100px 0;
		font-family: quicksand-med;
		font-style: normal;
		// font-weight: normal;
		font-size: 30px;
		line-height: 45px;
	}
	&-selectHeader {
		text-transform: uppercase;
		font-family: quicksand-med;
		font-style: normal;
		// font-weight: bold;
		font-size: 30px;
		line-height: 45px;
	}
	&__new {
		width: 100vw;
		height: 40vh;
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		& a {
			text-transform: capitalize;
			text-decoration: none;
			background-color: #82cecc;
			border-radius: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 10px;
			transition: 0.2s ease-in-out all;
			& div {
				width: 25vw;
				text-align: center;
				color: white;
				font-family: quicksand-med;
				font-style: normal;
				font-weight: normal;
				font-size: 24px;
				line-height: 30px;
			}
			&:hover {
				transform: scale(1.1);
			}
		}
	}
	&__old {
		& h4 {
			width: 100%;
			text-align: center;
			margin-bottom: 20px;
			font-family: quicksand-reg;
			font-style: normal;
			font-weight: normal;
			font-size: 24px;
			line-height: 30px;
		}
	}
}

@include mobile {
	.bookagreeDiv {
		height: 500vh;
		&__textDiv {
			width: 95vw;
			& h4 {
				font-size: 16px;
				margin: 15px 0;
			}
			& h5 {
				font-weight: 100;
			}
		}
	}
	.bookingFamilyNewOrOld {
		&-header {
			padding: 0 20px;
			font-size: 33px;
			text-align: center;
			margin-top: 100px;
		}
		&-selectHeader {
			text-align: center;
		}
		&-subHeader {
			margin-bottom: 20px;
		}
		&__new {
			height: 50vh;
			&-link {
				height: 300px;
				width: 40vw;
			}
		}
		&__old {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
		}
	}
}
