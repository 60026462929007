.insured {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;

	&__center {
		width: 70vw;
		border-radius: 10px;
		padding: 20px 20px 10px 20px;
		margin-top: 120px;

		& h2 {
			text-transform: capitalize;
			font-family: quicksand-bold;
			font-style: normal;
			font-weight: bold;
			font-size: 36px;
			line-height: 45px;
			color: #82cecc;
		}

		& div {
			margin: 20px 0;
			font-family: quicksand-reg;
			font-style: normal;
			// font-weight: bold;
			font-size: 25px;
			line-height: 35px;
		}
		& a {
			margin-left: 5px;
		}
	}
}

@include tablet {
	.insured {
		&__center {
			padding: 30px 20px 20px 30px;
			margin-top: 100px;
		}
	}
}

@include mobile {
	.insured {
		height: 100%;
		&__center {
			padding: 30px 20px 20px 30px;
			margin: 200px 0 70px 0;
			height: 100%;
			width: 90vw;
		}
	}
}
