.generalCenter {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;

	&-center {
		padding: 50px;
		width: 60vw;
		margin-top: 150px;
		& p {
			font-family: quicksand-bold;
			font-style: normal;
			font-weight: bold;
			font-size: 36px;
			line-height: 45px;

			color: #82cecc;
		}
		& h3 {
			font-family: quicksand-reg;
			font-style: normal;
			// font-weight: bold;
			font-size: 20px;
			line-height: 30px;
		}
		& h4 {
			font-family: quicksand-reg;
			font-style: normal;
			// font-weight: bold;
			font-size: 20px;
			line-height: 30px;
		}
		& section {
			font-family: quicksand-med;
			font-style: normal;
			// font-weight: bold;
			font-size: 23px;
			line-height: 30px;
			& a {
				color: #82cecc;
				text-decoration: none;
			}
		}
	}
	&-highlight {
		font-weight: 800;
		font-size: 24px;
	}
	&-highlight1 {
		font-weight: 900;
		font-size: 27px;
	}
}

.newFamilyPatient {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;

	&-center {
		padding: 50px;
		width: 70vw;
		margin-top: 120px;
		& section {
			font-family: quicksand-bold;
			font-style: normal;
			font-weight: bold;
			font-size: 36px;
			line-height: 45px;

			color: #82cecc;
		}
		& h3 {
			font-family: quicksand-reg;
			font-style: normal;
			font-weight: normal;
			font-size: 20px;
			line-height: 45px;
		}
		& h4 {
			font-family: quicksand-reg;
			font-style: normal;
			font-weight: normal;
			font-size: 20px;
			line-height: 45px;
		}
		& ol {
			& li {
				font-family: quicksand-reg;
				font-style: normal;
				font-weight: normal;
				font-size: 20px;
				line-height: 45px;
				color: #82cecc;
				& a {
					font-family: quicksand-reg;
					font-style: normal;
					font-weight: normal;
					font-size: 20px;
					line-height: 45px;
					color: #82cecc;
				}
			}
		}
	}
}
.generalInsideDiv {
	padding: 50px;
	width: 70vw;
	// margin-top: 120px;

	& h2 {
		font-family: quicksand-bold;
		font-style: normal;
		font-weight: bold;
		font-size: 36px;
		line-height: 45px;
		color: #82cecc;
	}
	& h3 {
		font-family: quicksand-reg;
		font-style: normal;
		// font-weight: bold;
		font-size: 25px;
		line-height: 35px;
		& a {
			color: #82cecc;
			text-decoration: none;
		}
	}
}
.virtualBooking {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
	&-center {
		padding: 50px;
		width: 70vw;
		margin-top: 120px;
		& h2 {
			font-family: quicksand-bold;
			font-style: normal;
			font-weight: bold;
			font-size: 36px;
			line-height: 45px;

			color: #82cecc;
		}
		& h3 {
			font-family: quicksand-reg;
			font-style: normal;
			// font-weight: bold;
			font-size: 25px;
			line-height: 35px;
		}
	}
}
.backButton {
	position: relative;
	// right: 200px;
	top: 75%;
  	padding-bottom: 30px;		/*change back button position from 40% to 75% and put padding bottom due to new information needed*/
	color: #82cecc;
	text-decoration: none;
}

.newsId {
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;

	&__center {
		padding: 180px 50px 0 50px;
		& div {
			padding: 30px 0;
			text-indent: 20px;
			font-size: 23px;
		}
	}
}

@include mobile {
	.newFamilyPatient {
		&-center {
			width: 95vw;
		}
	}
	.generalCenter {
		height: 100%;
		&-center {
			width: 95vw;
			margin-top: 100px;
			& h4 {
				width: 120px;
			}
		}
	}
	.bookingVirtual {
		height: 100vh;
	}
	.backButton {
		position: absolute;
		top: 65px;
		right: 10px;
		& img {
			height: 30px;
			width: 30px;
		}
	}
}
