.patientsTool {
	padding: 20px 0;
	padding-bottom: 40px;
	background-color: rgba(255, 255, 255, 1);
	font-family: "Times New Roman", Times, serif;

	&-header {
		margin: 100px 0 20px 70px;
		width: 90vw;
		font-family: quicksand-med;
		font-style: normal;
		font-weight: 500;
		font-size: 36px;
		line-height: 45px;
		color: #3a3a3a;
		// text-align: center;
	}

	&__buttonDiv {
		margin: 20px 0;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		// justify-content: space-evenly;
		// flex-wrap: wrap;
		// align-items: center;

		& div {
			display: flex;
			// width: 70vw;
		}

		&-link {
			color: black;
			text-decoration: none;
			border: 1px solid black;
			text-transform: capitalize;
			width: 360px;
			margin: 20px auto;
			height: 100px;
			transition: ease-in-out 0.2s all;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 5px 5px 0 5px;
			background: #82cecc;
			box-shadow: 3px 5px 15px rgba(0, 0, 0, 0.07);
			border-radius: 10px;
			border: none;
			color: white;
			font-size: 20px;
			& h5 {
				font-family: quicksand-med;
				font-style: normal;
				font-weight: 500;
				font-size: 22px;
				line-height: 30px;
				text-align: center;
			}

			&:hover {
				// background-color: #87ceeb;
				color: white;
				transform: scale(1.1);
			}
		}
	}
}

.resInformation {
	padding: 20px 0;
	background-color: rgba(255, 255, 255, 1);
	font-family: "Times New Roman", Times, serif;
	// border-top: 1px solid black;
	// #59c7c9

	&-header {
		margin: 20px 0 20px 70px;
		width: 90vw;
		font-family: quicksand-med;
		font-style: normal;
		font-weight: 500;
		font-size: 36px;
		line-height: 45px;
		color: #3a3a3a;
	}
	&__buttonDiv {
		margin: 20px 0;
		width: 90vw;
		display: flex;
		justify-content: space-evenly;
		&-button {
			width: 10%;
			height: 40px;
			background-color: transparent;
			text-transform: capitalize;
			&:hover {
				cursor: pointer;
			}
			&:focus {
				outline: none;
			}
		}
	}
}

@include tablet {
	// .patientsTool {
	// 	&__buttonDiv {
	// 		&-button {
	// 			& div {
	// 				& a {
	// 					& h5 {
	// 						padding: 20px;
	// 					}
	// 				}
	// 			}
	// 		}
	// 	}
	// }
	.resInformation {
		&__buttonDiv {
			&-button {
				height: 50px;
			}
		}
	}
}

@include mobile {
	.patientsTool {
		height: 100%;

		&__buttonDiv {
			flex-direction: column;
			align-items: center;
			margin: 0;
			// height: 100%;
			justify-content: space-between;
			&-link {
				margin: 0;
				width: 90vw;
				padding: 10px 0;
			}
			&-button {
				width: 80vw;
				padding: 10px 0;
			}
			& div {
				display: flex;
				flex-direction: column;
				align-items: center;
				& a {
					width: 90vw;
					margin: 20px 0;
				}
			}
		}
	}
	.resInformation {
		&__buttonDiv {
			flex-direction: column;
			align-items: center;
			margin: 0;
			height: 370px;
			justify-content: space-between;
			&-button {
				width: 80vw;
			}
		}
	}
}
