.formsDiv {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;

	&-header {
		margin: 120px 0 20px 0;
		font-family: quicksand-bold;
		font-style: normal;
		font-weight: bold;
		font-size: 36px;
		line-height: 45px;
		/* identical to box height */
		text-align: center;
		color: #82cecc;
	}
	&__linkDiv {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-evenly;
		width: 50vw;
		margin-bottom: 200px;
		&-link {
			color: white;
			text-decoration: none;
			margin: 20px 0;
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			background: #82cecc;
			border-radius: 7px;
			transition: ease-in-out 0.2s all;
			height: 85px;
			& h4 {
				padding: 10px;
			}
			&:hover {
				transform: scale(1.1);
			}
		}
	}
}

@include tablet {
	.formsDiv {
		&-header {
			margin: 120px 0 10px 0;
		}
		&__linkDiv {
			&-link {
				& h4 {
					font-size: 17px;
				}
			}
		}
	}
}

@include mobile {
	.formsDiv {
		&-header {
			margin: 190px 0 10px 0;
		}
		&__linkDiv {
			width: 85vw;
			&-link {
				height: 100px;
			}
		}
	}
}
