.adminControl {
	width: 100vw;
	height: 100%;

	&__englishDiv {
		padding: 150px 20px 50px 20px;
		&-header {
			text-transform: capitalize;
			margin-bottom: 20px;
		}
		&-Inner {
			width: 70vw;
			display: flex;
			flex-direction: column;
			& textarea {
				margin: 20px 0;
				border-radius: 10px;
				padding-left: 5px;
				font-size: 20px;
				border: 2px solid black;
				&:focus {
					outline: none;
					border: 2px solid rgb(135, 206, 235);
				}
			}
			& input {
				border-radius: 10px;
				height: 50px;
				padding-left: 5px;
				font-size: 20px;
				&:focus {
					outline: none;
					border: 2px solid rgb(135, 206, 235);
				}
			}
		}
	}
	&__chineseDiv {
		padding: 0 20px 50px 20px;
		&-header {
			text-transform: capitalize;
		}
		&-Inner {
			width: 70vw;
			display: flex;
			flex-direction: column;
			& textarea {
				margin: 20px 0;
				border-radius: 10px;
				padding-left: 5px;
				font-size: 20px;
				border: 2px solid black;
				&:focus {
					outline: none;
					border: 2px solid rgb(135, 206, 235);
				}
			}
			& input {
				border-radius: 10px;
				height: 50px;
				padding-left: 5px;
				font-size: 20px;
				&:focus {
					outline: none;
					border: 2px solid rgb(135, 206, 235);
				}
			}
		}
	}
}
