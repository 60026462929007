.agreementDiv {
	height: 120vh;
	width: 100vw;
	background-color: rgba(0, 0, 0, 0.6);
	position: absolute;
	// margin-top: -20vh;
	justify-content: center;
	// align-items: flex-start;
	align-items: center;
	z-index: 2;
	&__textDiv {
		// margin-top: 20px;
		padding: 20px 150px;
		//padding-bottom: 0;		
		//height: 700px;			/*have same change with main.css ---- change white pop up box size everytime */
		width: 1000px;
		background-color: white;
		// margin-top: 50px;
		flex-direction: column;
		border-radius: 10px;

		& h3 {
			font-family: quicksand-reg;
			font-style: normal;
			font-weight: normal;
			font-size: 18px;
			line-height: 22px;
			margin: 20px 0 0 0;
		}
		& h4 {
			margin-left: auto;
			width: 130px;
			font-family: quicksand-reg;
			font-style: normal;
			font-weight: normal;
			font-size: 18px;
			line-height: 22px;
		}
		& button {
			display: float;
			// margin: 0 auto 10vh;
			margin: 30px auto 0 auto;
			width: 150px;
			height: 40px;
			font-family: quicksand-reg;
			font-style: normal;
			font-weight: normal;
			font-size: 18px;
			line-height: 22px;
			border: none;
			background-color: #82cecc;
			color: white;

			&:hover {
				cursor: pointer;
			}
		}
		& section {
			text-align: center;
			font-family: quicksand-med;
			font-style: normal;
			font-weight: 500;
			font-size: 24px;
			line-height: 30px;
			padding-bottom: 30px;
		}
		& ol {
			& li {
				font-family: quicksand-reg;
				font-style: normal;
				font-weight: normal;
				font-size: 18px;
				line-height: 22px;
			}
		}
	}
}

@include mobile {
	.agreementDiv {
		// height: 320vh;
		&__textDiv {
			width: 95vw;
			padding: 20px 20px;
			height: 1000px;
			& section {
				margin-top: 20px;
			}
		}
	}
}
