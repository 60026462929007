.clinicServiceDiv {
	padding: 20px 30px 0 30px;
	height: 70vh;
	overflow-x: hidden;
	overflow-x: auto;
	text-align: justify;
	&-header {
		text-transform: capitalize;
		font-weight: 900;
		font-size: 40px;
	}
	&-section {
		font-family: quicksand-reg;
		font-style: normal;
		font-weight: normal;
		font-size: 24px;
		line-height: 30px;
	}
	&-ul {
		& li {
			font-family: quicksand-reg;
			font-style: normal;
			font-weight: normal;
			font-size: 22px;
			line-height: 30px;
			margin: 10px 0;
		}
	}
}
@include mobile {
	.clinicServiceDiv {
		// height: 100%;
		&-header {
			text-align: center;
		}
		&-section {
			margin: 10px 0;
			line-height: 25px;
			font-size: 20px;
		}
		& ul {
			padding: 0;
			& li {
				margin: 10px 0;
				font-size: 18px;
			}
		}
	}
}
