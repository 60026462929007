.faq {
	display: flex;
	justify-content: center;
	// align-items: center;
	height: 100%;
	width: 100vw;

	&__centerDiv {
		margin: 120px 0 50px 0;
		&-header {
			margin-bottom: 10px;
			margin-top: 30px;
		}
		&__questDiv {
			height: 200px;
			width: 70vw;
			display: flex;
			flex-direction: column;
			overflow-x: auto;
			height: 70vh;
			& div {
				font-size: 20px;
				// text-align: center;
				padding: 10px 20px;
				font-weight: 600;
				font-family: quicksand-med;
				font-style: normal;
				font-weight: 500;
				font-size: 20px;
				line-height: 25px;
				/* identical to box height */

				// text-align: center;
				&:hover {
					cursor: pointer;
				}
			}
			& h4 {
				background-color: white;
				margin: 0;
				padding: 20px;
				font-family: quicksand-reg;
				font-style: normal;
				font-weight: normal;
				font-size: 20px;
				line-height: 25px;
				& img {
					margin-right: 10px;
				}
			}
		}
	}
}

@include mobile {
	.faq {
		&__centerDiv {
			&-header {
				padding: 10px 0 0 30px;
			}
			&__questDiv {
				width: 95vw;
			}
		}
	}
}
