.contactPage {
	padding: 100px 5vw 0 5vw;
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	&__location1 {
		display: flex;
		padding: 30px 0;
		border-radius: 10px;
		width: 80vw;
		// margin-top: 100px;
		&__infoDiv {
			width: 30vw;
			padding-left: 5vw;
			width: 50%;
			& h2 {
				margin: 0;
				margin-bottom: 20px;
				font-family: quicksand-bold;
				font-style: normal;
				font-weight: 500;
				font-size: 25px;
				line-height: 22px;
			}
			& h3 {
				font-family: quicksand-med;
				font-style: normal;
				font-weight: 500;
				font-size: 23px;
				line-height: 22px;
			}
			&-hour {
				& h3 {
					margin: 20px 0 10px 0;
					// margin-left: 2vw;
				}
				& h4 {
					font-family: quicksand-reg;
					font-style: normal;
					font-weight: 500;
					font-size: 23px;
					line-height: 22px;
				}
			}
			&-address {
				margin-top: 20px;
				& h3 {
					margin-bottom: 10px;
					// margin-left: 2vw;
				}
				& h4 {
					margin: 10px 0 0 0;
					font-weight: 300;
				}
				& a {
					font-weight: 550;
					font-size: 18px;
					margin: 10px 0 0 0;
					color: black;
					text-decoration: none;
				}
			}
		}
		&-img {
			width: 40vw;
			height: 45vh;
			margin: auto;
		}
	}
	&__location2 {
		margin: 70px 0;
	}
}

// .trial {
// 	height: 20vh;
// 	width: 20vw;
// }

@include extraLarge {
	.contactPage {
		height: 100vh;
	}
}
@include tablet {
	.contactPage {
		&__location1 {
			width: 85vw;
		}
	}
}

@include mobile {
	.contactPage {
		&__location1 {
			width: 90vw;
			&__infoDiv {
				width: 100%;
			}
			&-img {
				display: none;
			}
		}
	}
}
