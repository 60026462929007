.renewal {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;

	&__center {
		width: 70vw;
		// height: 50vh;
		padding: 30px;
		margin-top: 50px;
		&-header {
			text-transform: capitalize;
			font-family: quicksand-bold;
			font-style: normal;
			font-weight: bold;
			font-size: 36px;
			line-height: 45px;
			color: #82cecc;
		}
		& div {
			margin: 20px 0;
			font-family: quicksand-reg;
			font-style: normal;
			// font-weight: bold;
			font-size: 25px;
			line-height: 35px;
		}
	}
}

@include tablet {
	.renewal {
		&__center {
			height: 70vh;
			&-header {
				font-size: 30px;
			}
			& div {
				font-size: 20px;
			}
		}
	}
}

@include mobile {
	.renewal {
		height: 100%;
		&__center {
			margin: 190px 0 50px 0;
			height: 100%;
			width: 90vw;
			&-header {
				font-size: 25px;
			}
			& div {
				font-size: 19px;
			}
		}
	}
}
