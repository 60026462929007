.chart {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;

	&__center {
		width: 60vw;

		padding: 50px;
		margin-top: 120px;
		&-header {
			margin-bottom: 20px;
			font-family: quicksand-bold;
			font-style: normal;
			font-weight: bold;
			font-size: 36px;
			line-height: 45px;
			color: #82cecc;
		}
		& div {
			padding-left: 30px;
			font-family: quicksand-reg;
			font-style: normal;
			// font-weight: bold;
			font-size: 25px;
			line-height: 35px;
		}
	}
}

@include tablet {
	.chart {
		&__center {
			padding: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			&-header {
				font-size: 30px;
			}
			& div {
				font-size: 20px;
				width: 100%;
			}
		}
	}
}

@include mobile {
	.chart {
		height: 100vh;
		&__center {
			padding: 10px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			// height: 70vh;
			width: 90vw;
			margin: 130px 0 50px 0;
			&-header {
				font-size: 30px;
			}
			& div {
				font-size: 20px;
				width: 100%;
				margin: 5px 0;
				padding-left: 0;
			}
		}
	}
}
