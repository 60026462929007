.clinicDoctorDiv {
	// font-family: "Times New Roman", Times, serif;
	padding: 20px 30px 0 30px;

	&-textOverDiv {
		overflow-x: hidden;
		overflow-x: auto;
		text-align: justify;
		height: 55vh;
	}
	&-header {
		text-transform: capitalize;
		font-weight: 900;
		font-size: 40px;
		// font-family: "Times New Roman", Times, serif;
		margin-bottom: 30px;
	}

	&__section {
		display: flex;
		align-items: center;
		padding: 0 40px 0 0;
		margin: 15px 0;
		&-header {
			font-family: quicksand-bold;
			font-style: normal;
			font-weight: normal;
			font-size: 27px;
			line-height: 30px;
			margin: 10px 0 20px 0;
		}
		&__Div {
			&-img {
				// border-radius: 50%;
				height: 120px;
				width: 120px;
				margin-right: 20px;
			}
			& section {
				text-indent: 20px;
				font-family: quicksand-reg;
				font-style: normal;
				font-weight: normal;
				font-size: 20px;
				line-height: 30px;
				& h4 {
					font-size: 20px;
				}
			}
		}
	}
}

.clinicDoctorMaleDiv {
	&__section {
		display: flex;
		flex-direction: row-reverse;
		align-items: center;
		&-header {
			font-size: 30px;
			font-weight: 600;
			margin: 10px 0 20px 0;
		}
		&__Div {
			&-img {
				margin: 0 10px;
				// border-radius: 50%;
				height: 120px;
				width: 120px;
			}
			& section {
				font-size: 20px;
				text-indent: 20px;
				font-weight: 200;
			}
		}
	}
}

// .genderChangeDiv {
// 	margin-top: 30px;
// }
// .paddingLeft {
// 	padding-right: 20px;
// }
@include mobile {
	.clinicDoctorDiv {
		padding: 0;
		&-header {
			text-align: center;
			margin: 20px 0 0 0;
		}
		&__section {
			padding: 10px 30px;
			flex-direction: column;

			&__Div {
				&-img {
					height: 70px;
					width: 70px;
					margin: 20px 0;
				}
			}
		}
	}
}
