.schedulePage {
	width: 100vw;
	height: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	// align-items: flex-end;
}
.scheduleTable {
	width: 80vw;
	height: 70vh;
	border: 1px solid black;
	margin-bottom: 2vh;
	border-spacing: 0;
	background-color: rgba(255, 255, 255, 0.9);
	// font-family: "Times New Roman", Times, serif;
	margin-top: 140px;
	&__tHead {
		width: 100%;

		& tr {
			height: calc(70vh / 10);
			width: 100%;
			& th {
				// border: 1px solid black;
				width: calc(80vw / 7);

				text-align: center;
				// background-color: rgba(107, 143, 156, 0.3);
				font-family: quicksand-med;
				font-style: normal;
				font-weight: 500;
				font-size: 24px;
				line-height: 30px;
				background-color: #82cecc;
				color: white;
			}
		}
	}
	&__tBody {
		& tr {
			height: calc(70vh / 7);
			width: 100%;
			& td {
				border: 1px solid black;
				width: calc(80vw / 7);
				text-align: center;
				font-family: quicksand-med;
				font-style: normal;
				font-weight: 500;
				font-size: 20px;
				line-height: 30px;
				/* identical to box height */

				text-align: center;
			}
		}

		&-highlight1 {
			background-color: rgba(130, 206, 204, 0.1);
		}
	}
}

@include mobile {
	.scheduleTable {
		width: 100vw;
		& th {
			font-size: 12px;
		}
		& td {
			font-size: 12px;
		}
	}
}
