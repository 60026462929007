@font-face {
  font-family: "opensans-reg";
  src: url(../assets/fonts/Open_Sans/static/OpenSans-Regular.ttf);
}

@font-face {
  font-family: "opensans-med";
  src: url(../assets/fonts/Open_Sans/static/OpenSans-Medium.ttf);
}

@font-face {
  font-family: "opensans-bold";
  src: url(../assets/fonts/Open_Sans/static/OpenSans-Bold.ttf);
}

.navDiv {
  z-index: 2;
  background-color: white;
  position: fixed;
  width: 100vw;
  padding: 0 20px;
  height: 100px;
  height: 100px;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1);
}

.navDiv__mobile {
  display: none;
}
.nav-mobile-section {
  text-decoration: none;
}
.navDiv__navBar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 70%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
}

.navLeft {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}
.navLeft .jvonLogo {
  height: 50px;
}

.navDiv__navBar-title h5,
.navDiv__navBar-title section {
  font-family: opensans-reg;
  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem;
}

.navDiv__navBar-title img {
  height: 19px;
  width: 19px;
  margin-right: 10px;
}

.navDiv__navBar-title a {
  color: black;
  text-decoration: none;
}

.navRight {
  display: flex;
  gap: 1.5rem;
}

.navDiv__navBar__links {
  font-family: opensans-reg;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 30px;
}

.navDiv__navBar__links-link {
  color: black;
  text-decoration: none;
  margin: 0 0.8rem;
}

.navDiv__navBar-button {
  /* margin-left: auto; */
  display: block;
  border: none;
  background-color: white;
  color: #32817d;
  font-family: opensans-med;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 30px;
}

.navDiv__navBar-button:focus {
  outline: none;
}

@media only screen and (min-width: 768px) and (max-width: 1300px) {
  .navDiv {
    height: 70px;
    padding: 0;
  }
  .navDiv__mobilePopOut {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 100vh;
    padding: 0 20px;
  }
  .navDiv__mobilePopOut-button {
    width: 30vw;
    border: none;
    background-color: white;
    -ms-flex-item-align: start;
    align-self: flex-start;
    margin-top: 20px;
    color: #32817d;
    font-family: opensans-med;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }
  .navDiv__mobilePopOut__links {
    margin: 0;
    width: 60vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .navDiv__mobilePopOut__links-link {
    width: 100%;
    text-align: right;
    font-family: opensans-med;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: black;
    margin: 20px 0;
  }
  .navDiv__navBar {
    display: none;
  }
  .navDiv__mobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 70px;
    position: fixed;
    width: 100vw;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 15px;
  }
  .navDiv__mobile-logo {
    height: 50px;
    width: 50px;
    margin-right: 10px;
  }
  .navDiv__mobile-menu {
    height: 20px;
    width: 50px;
    display: block;
    margin-left: auto;
  }
  .navDiv__mobile div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .navDiv__mobile div img {
    height: 20px;
    width: 20px;
  }
  .navDiv__mobile div section {
    margin-top: 10px;
  }
  .navDiv__mobile h4 {
    font-size: 18px;
    margin: 0;
    text-align: center;
  }
  .navDiv__mobile-secondH4 {
    font-weight: 100;
  }
  .navDiv__mobile a {
    text-decoration: none;
    color: black !important;
  }
}

@media only screen and (max-width: 767px) {
  .navDiv {
    height: 70px;
    padding: 0;
  }
  .navDiv__mobilePopOut {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 100vh;
    padding: 0 20px;
  }
  .navDiv__mobilePopOut-button {
    width: 30vw;
    border: none;
    background-color: white;
    -ms-flex-item-align: start;
    align-self: flex-start;
    margin-top: 20px;
    color: #32817d;
    font-family: opensans-med;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }
  .navDiv__mobilePopOut__links {
    margin: 0;
    width: 60vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .navDiv__mobilePopOut__links-link {
    width: 100%;
    text-align: right;
    font-family: opensans-med;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: black;
    margin: 20px 0;
  }
  .navDiv__navBar {
    display: none;
  }
  .navDiv__mobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 70px;
    position: fixed;
    width: 100vw;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 15px;
  }
  .navDiv__mobile-logo {
    height: 50px;
    width: 50px;
    margin-right: 10px;
  }
  .navDiv__mobile-menu {
    height: 20px;
    width: 50px;
    display: block;
    margin-left: auto;
  }
  .navDiv__mobile div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .navDiv__mobile div img {
    height: 20px;
    width: 20px;
  }
  .navDiv__mobile div section {
    margin-top: 10px;
  }
  .navDiv__mobile h4 {
    font-size: 18px;
    margin: 0;
    text-align: center;
  }
  .navDiv__mobile-secondH4 {
    font-weight: 100;
  }
  .navDiv__mobile a {
    text-decoration: none;
    color: black !important;
  }
}

.slideShow {
  height: 500px;
  width: 100vw;
}

.slideShow__slides {
  height: 500px;
  width: 100vw;
}

.slideShow__slides-1 {
  background-image: url(../assets/downloadImages/rsz_mainpage1.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100vw;
}

.slideShow__slides-2 {
  background-image: url(../assets/downloadImages/rsz_mainpage2.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  width: 100vw;
}

.slideShow__slides-3 {
  background-image: url(../assets/NewsBanner/WechatIMG2.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  width: 100vw;
}

.slideShow__slides-4 {
  background-image: url(../assets/clinicBackgroundPic/banner-bright.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100vw;
}

.banner-annoucement {
  color: white;
  padding: 8rem 14rem;
}
.banner-annoucement .banner-annoucement-title {
  margin-bottom: 1.2rem;
}
.banner-annoucement h3 {
  font-size: 1.4rem;
}
.banner-annoucement p {
  font-size: 1.3rem;
}
.banner-annoucement a {
  color: white;
  decoration: none;
  font-weight: 700;
}

.react-slideshow-container .nav:last-of-type {
  margin-left: -60px !important;
  z-index: 1 !important;
}

.react-slideshow-container .nav:first-of-type {
  z-index: 1 !important;
}
.firstSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.firstSection .news-section {
  width: 70%;
  margin: 0 auto;
  padding-top: 8rem;
  padding-bottom: 6rem;
}
.firstSection .news-important-part {
  font-weight: 700;
}

.testimonials-section {
  width: 100%;
  background-color: rgba(50, 129, 125, 0.08);
}

.testimonials-section-inner-wrapper {
  width: 70%;
  margin: 0 auto;
  padding: 6rem 0;
}
.slide-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8rem;
  margin-top: 1.2rem;
}
.slide-button-container .slide-booking-button {
  background-color: white;
  border: none;
  outline: none;
  border-radius: 14px;
  padding: 16px 35px;
  cursor: pointer;
  color: #32817d;
  font-weight: 700;
  font-size: 2rem;
}

.something {
  height: 100%;
  width: 100vw;
}

.clinicBooking {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.clinicBooking-header {
  margin: 60px 0 18px 80px;
  font-family: opensans-med;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 45px;
}

.clinicBooking a {
  color: black;
  text-decoration: none;
  border: 1px solid black;
  text-transform: capitalize;
  width: 360px;
  margin: 20px auto;
  height: 100px;
  -webkit-transition: ease-in-out 0.2s all;
  transition: ease-in-out 0.2s all;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-shadow: 3px 5px 15px rgba(0, 0, 0, 0.07);
  box-shadow: 3px 5px 15px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: none;
  font-family: opensans-med;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
}

.clinicBooking a:hover {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.clinicBooking__ButtonDiv-familyLink {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(../assets/icons/icon-family_doctor.png);
}

.clinicBooking__ButtonDiv-walkInLink {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(../assets/icons/icon-walk_in.png);
}

.clinicBooking__ButtonDiv-virtualLink {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(../assets/icons/icon-book_virtual.png);
}

.clinicLocation__firstDiv {
  display: flex;
  align-items: center;
  gap: 4rem;
  width: 70%;
  margin: 0 auto;
  padding: 6rem 0;
}
.contactPage__location1-img-container {
  width: 70%;
}
.contactPage__location1-img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.clinicLocation__firstDiv__location {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.clinicLocation-inner-div:not(:last-child) {
  margin-bottom: 4rem;
}
.clinicLocation-inner-div a {
  color: black;
}
.clinicLocation__firstDiv__location p {
  margin-top: 0;
  margin-bottom: 0;
}
.clinicLocation__firstDiv__location-header {
  font-size: 1.3rem;
  font-weight: 700;
}

.clinicLocation__firstDiv__doctorDiv {
  width: 70%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 4rem 0;
}

.clinicLocation__firstDiv__doctorDiv-section img {
  height: 100px;
  width: 100px;
  margin-bottom: 30px;
}

.clinicLocation__firstDiv__doctorDiv-section h4 {
  font-family: opensans-reg;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
}

.footer-container {
  background: #fff;
  box-shadow: 0px -4px 15px 0px rgba(0, 0, 0, 0.1);
}
.footer-inner-container {
  display: flex;
  justify-content: space-between;
  width: 70%;
  padding: 4rem 2rem;
  margin: 0 auto;
}

.footer-container .footer-left,
.footer-container .footer-right {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.footer-container .footer-right p {
  margin-top: 0;
  margin-bottom: 0;
}
.footer-container .footer-right a {
  color: black;
}

@media (max-width: 768px) {
  .testimonials-section-inner-wrapper {
    width: 100%;
  }

  .slide-button-container {
    flex-direction: column;
    gap: 1.4rem;
  }

  .slide-button-container .slide-booking-button {
    padding: 12px 30px;
    font-size: 1.5rem;
  }

  .clinicLocation__firstDiv {
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    padding: 2rem 0;
  }

  .contactPage__location1-img-container {
    width: 100%;
  }

  .clinicLocation-header {
    text-align: center;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .clinicLocation__firstDiv__location {
    width: 100%;
    align-items: center;
    text-align: center;
  }

  .clinicLocation-inner-div {
    margin-bottom: 2rem;
  }

  .clinicLocation-inner-div a,
  .clinicLocation-inner-div p {
    display: block;
  }

  .clinicLocation__firstDiv__doctorDiv {
    width: 100%;
    overflow-x: auto;
    padding: 1.5rem 0;
    display: flex;
    flex-wrap: nowrap;
    align-items: initial;
    justify-content: initial;
  }

  .clinicLocation__firstDiv__doctorDiv-section {
    flex: 0 0 auto;
    margin-right: 20px;
  }

  .footer-inner-container {
    width: 100%;
  }

  .jvonLogo {
    height: 30px;
  }
  .locIcon {
    height: 20px;
  }
}

.emptyHeaderDiv {
  display: block;
  height: 100px;
}

.backgroundChanger {
  height: 500px;
  width: 100vw;
  -webkit-animation: backgroundChanger 20s infinite normal;
  animation: backgroundChanger 20s infinite normal;
}

.clinicNews-header {
  font-family: opensans-med;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 45px;
  margin: 65px 0 0 80px;
}

.clinicNews a {
  margin-left: 80px;
  font-family: opensans-reg;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  /* identical to box height */
  color: #32817d;
}

.clinicNews a h5 {
  font-family: opensans-reg;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 30px;
  color: black;
  border-bottom: 2px solid #e4e4e4;
  padding-bottom: 23px;
  margin-left: 80px;
}

.clinicNews section {
  margin-top: 15px;
}

@-webkit-keyframes backgroundChanger {
  0% {
    background: url("../assets/downloadImages/mainPage1.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  50% {
    background: url("../assets/downloadImages/mainPage2.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  100% {
    background: url("../assets/downloadImages/mainPage1.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@keyframes backgroundChanger {
  0% {
    background: url("../assets/downloadImages/mainPage1.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  50% {
    background: url("../assets/downloadImages/mainPage2.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  100% {
    background: url("../assets/downloadImages/mainPage1.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1300px) {
  /* .clinicLocation-header {
    height: 30px;
    margin: 10px 0;
  }
  .clinicLocation-achor {
    margin-left: 10px;
    font-size: 10px;
  }
  .clinicLocation-Text1 {
    font-size: 13px;
  }
  .clinicLocation-Text2 {
    font-size: 10px;
  }
  .clinicLocation-body1 {
    height: 150px;
    padding-bottom: 5px;
  }
  .clinicLocation-body1 h1,
  .clinicLocation-body1 h2,
  .clinicLocation-body1 h3,
  .clinicLocation-body1 h4 {
    margin: 0 0 0 10px;
  }
  .clinicLocation-numberDiv {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 20px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .clinicLocation__doctorDiv {
    -ms-flex-flow: wrap;
    flex-flow: wrap;
    height: 60px;
    margin: 10px 0 0 0;
  }
  .clinicLocation__doctorDiv__section {
    height: 30px;
    width: 60px;
    margin: 3px 0;
  } */
}

@media only screen and (max-width: 767px) {
  .clinicBooking {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .clinicBooking-header {
    font-size: 30px;
    line-height: 22px;
    margin: 60px 0 0 15px;
    text-align: center;
  }
  .clinicNews-header {
    text-align: center;
    margin-left: 0;
  }
  .clinicNews a h5 {
    margin: 0 10px;
    text-align: center;
  }
  .clinicNews section {
    text-align: center;
  }
  .clinicNews section a {
    margin: 0;
  }
  .backgroundChanger {
    width: 100vw;
    height: 200px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .emptyHeaderDiv {
    height: 70px;
  }
  .slideShow {
    height: 190px;
    width: 100vw;
  }
  .slideShow__slides {
    height: 190px;
    width: 100vw;
  }
  .slideShow__slides-1 {
    background-image: url(../assets/downloadImages/mobileMain1.png);
  }
  .slideShow__slides-2 {
    background-image: url(../assets/downloadImages/mobileMain2.png);
    height: 100%;
    background-size: cover;
  }
  .slideShow__slides-3 {
    background-image: url(../assets/NewsBanner/WechatIMG1.png);
    background-size: contain;
  }
  .react-slideshow-container .nav:last-of-type {
    margin-left: -30px !important;
  }
  .react-slideshow-container .nav:first-of-type {
    padding-left: 0px !important;
  }
  .react-slideshow-container .nav {
    background: none !important;
  }
}

.patientsTool {
  padding: 20px 0;
  padding-bottom: 40px;
  background-color: white;
  font-family: "Times New Roman", Times, serif;
}

.patientsTool-header {
  margin: 100px 0 20px 70px;
  width: 90vw;
  font-family: opensans-med;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 45px;
  color: #3a3a3a;
}

.patientsTool__buttonDiv {
  margin: 20px 0;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.patientsTool__buttonDiv div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.patientsTool__buttonDiv-link {
  color: black;
  text-decoration: none;
  border: 1px solid black;
  text-transform: capitalize;
  width: 360px;
  margin: 20px auto;
  height: 100px;
  -webkit-transition: ease-in-out 0.2s all;
  transition: ease-in-out 0.2s all;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 5px 5px 0 5px;
  background: #32817d;
  -webkit-box-shadow: 3px 5px 15px rgba(0, 0, 0, 0.07);
  box-shadow: 3px 5px 15px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: none;
  color: white;
  font-size: 20px;
}

.patientsTool__buttonDiv-link h5 {
  font-family: opensans-med;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
}

.patientsTool__buttonDiv-link:hover {
  color: white;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.resInformation {
  padding: 20px 0;
  background-color: white;
  font-family: "Times New Roman", Times, serif;
}

.resInformation-header {
  margin: 20px 0 20px 70px;
  width: 90vw;
  font-family: opensans-med;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 45px;
  color: #3a3a3a;
}

.resInformation__buttonDiv {
  margin: 20px 0;
  width: 90vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
}

.resInformation__buttonDiv-button {
  width: 10%;
  height: 40px;
  background-color: transparent;
  text-transform: capitalize;
}

.resInformation__buttonDiv-button:hover {
  cursor: pointer;
}

.resInformation__buttonDiv-button:focus {
  outline: none;
}

@media only screen and (min-width: 768px) and (max-width: 1300px) {
  .resInformation__buttonDiv-button {
    height: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .patientsTool {
    height: 100%;
  }
  .patientsTool__buttonDiv {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .patientsTool__buttonDiv-link {
    margin: 0;
    width: 90vw;
    padding: 10px 0;
  }
  .patientsTool__buttonDiv-button {
    width: 80vw;
    padding: 10px 0;
  }
  .patientsTool__buttonDiv div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .patientsTool__buttonDiv div a {
    width: 90vw;
    margin: 20px 0;
  }
  .resInformation__buttonDiv {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0;
    height: 370px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .resInformation__buttonDiv-button {
    width: 80vw;
  }
}

.formsDiv {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
}

.formsDiv-header {
  margin: 120px 0 20px 0;
  font-family: opensans-bold;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
  /* identical to box height */
  text-align: center;
  color: #32817d;
}

.formsDiv__linkDiv {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  width: 50vw;
  margin-bottom: 200px;
}

.formsDiv__linkDiv-link {
  color: white;
  text-decoration: none;
  margin: 20px 0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  background: #32817d;
  border-radius: 7px;
  -webkit-transition: ease-in-out 0.2s all;
  transition: ease-in-out 0.2s all;
  height: 85px;
}

.formsDiv__linkDiv-link h4 {
  padding: 10px;
}

.formsDiv__linkDiv-link:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

@media only screen and (min-width: 768px) and (max-width: 1300px) {
  .formsDiv-header {
    margin: 120px 0 10px 0;
  }
  .formsDiv__linkDiv-link h4 {
    font-size: 17px;
  }
}

@media only screen and (max-width: 767px) {
  .formsDiv-header {
    margin: 190px 0 10px 0;
  }
  .formsDiv__linkDiv {
    width: 85vw;
  }
  .formsDiv__linkDiv-link {
    height: 100px;
  }
}

.agreementDiv {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 2;
}

.agreementDiv__section {
  padding-top: 10px;
}

.agreementDiv__textDiv {
  overflow-y: scroll;
  padding: 20px 150px;
  /*padding-bottom: 0;*/
  /*height: 700px;                 /*change pop up information white box with suitable size everytime*/
  max-height: 95vh;
  width: 1000px;
  background-color: white;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 10px;
}

.letterDiv__textDiv {
  overflow-y: scroll;
  padding: 20px 50px;
  /*padding-bottom: 0;*/
  /*height: 700px;                 /*change pop up information white box with suitable size everytime*/
  max-height: 95vh;
  width: auto;
  margin: auto 50px;
  background-color: white;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 10px;
}

.letterDiv__textDiv .changeLang {
  float: right;
  color: #32817d;
  font-weight: bold;
  cursor: pointer;
}

.letterDiv__textDiv .warning {
  font-weight: bold;
}

.letterDiv__textDiv .danger {
  font-weight: bold;
  color: red;
}

.agreementDiv__textDiv h3 {
  font-family: opensans-reg;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  margin: 20px 0 0 0;
}

.agreementDiv__textDiv h4 {
  margin-left: auto;
  width: 130px;
  font-family: opensans-reg;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
}

.agreementDiv__buttonDiv {
  margin: 0 auto;
  text-align: center;
}

.agreementDiv__textDiv button,
.letterDiv__textDiv button {
  display: float;
  margin: 30px auto 0 auto;
  width: 150px;
  height: 40px;
  font-family: opensans-reg;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  border: none;
  background-color: #32817d;
  color: white;
}

.agreementDiv__textDiv button:hover,
.letterDiv__textDiv button:hover {
  cursor: pointer;
}

.agreementDiv__textDiv section {
  text-align: center;
  font-family: opensans-med;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  padding-bottom: 30px;
}

.agreementDiv__textDiv ol li {
  font-family: opensans-reg;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
}

@media only screen and (max-width: 767px) {
  .agreementDiv__textDiv {
    width: 95vw;
    padding: 20px 20px;
    height: 1000px;
  }
  .agreementDiv__textDiv section {
    margin-top: 20px;
  }
}

.bookagreeDiv {
  height: 300vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  z-index: 2;
}

.bookagreeDiv__textDiv {
  background-color: white;
  width: 60vw;
  margin-top: 10vh;
  padding: 30px;
  border-radius: 10px;
}

.bookagreeDiv__textDiv h5 {
  font-weight: 300;
}

.bookagreeDiv__textDiv li {
  margin-top: 8px;
}

.bookagreeDiv__textDiv h6 {
  font-size: 17px;
  display: block;
  margin-left: auto;
  width: 425px;
}

.bookagreeDiv__textDiv button {
  display: block;
  width: 100px;
  margin: 10px auto 0;
  height: 30px;
}

.bookagreeDiv__textDiv button:hover {
  cursor: pointer;
}

.bookagreeDiv__emergencyDiv {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.bookagreeDiv__emergencyDiv h3 {
  color: red;
  font-size: 25px;
  margin-bottom: 10px;
}

.bookagreeDiv__emergencyDiv h4 {
  font-weight: 500;
  color: red;
  line-height: 30px;
}

.bookagreeDiv a {
  text-transform: capitalize;
}

.bookingFamilyNewOrOld {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100vh;
}

.bookingFamilyNewOrOld-header {
  margin: 170px 0 0 0;
  color: #32817d;
  font-family: opensans-bold;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
}

.bookingFamilyNewOrOld-subHeader {
  margin: 20px 0 100px 0;
  font-family: opensans-med;
  font-style: normal;
  font-size: 30px;
  line-height: 45px;
}

.bookingFamilyNewOrOld-selectHeader {
  text-transform: uppercase;
  font-family: opensans-med;
  font-style: normal;
  font-size: 30px;
  line-height: 45px;
}

.bookingFamilyNewOrOld__new {
  width: 100vw;
  height: 40vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
}

.bookingFamilyNewOrOld__new a {
  text-transform: capitalize;
  text-decoration: none;
  background-color: #32817d;
  border-radius: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px;
  -webkit-transition: 0.2s ease-in-out all;
  transition: 0.2s ease-in-out all;
}

.bookingFamilyNewOrOld__new a div {
  width: 25vw;
  text-align: center;
  color: white;
  font-family: opensans-med;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
}

.bookingFamilyNewOrOld__new a:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.bookingFamilyNewOrOld__old h4 {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  font-family: opensans-reg;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
}

@media only screen and (max-width: 767px) {
  .bookagreeDiv {
    height: 500vh;
  }
  .bookagreeDiv__textDiv {
    width: 95vw;
  }
  .bookagreeDiv__textDiv h4 {
    font-size: 16px;
    margin: 15px 0;
  }
  .bookagreeDiv__textDiv h5 {
    font-weight: 100;
  }
  .bookingFamilyNewOrOld-header {
    padding: 0 20px;
    font-size: 33px;
    text-align: center;
    margin-top: 100px;
  }
  .bookingFamilyNewOrOld-selectHeader {
    text-align: center;
  }
  .bookingFamilyNewOrOld-subHeader {
    margin-bottom: 20px;
  }
  .bookingFamilyNewOrOld__new {
    height: 50vh;
  }
  .bookingFamilyNewOrOld__new-link {
    height: 300px;
    width: 40vw;
  }
  .bookingFamilyNewOrOld__old {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.clinicPage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.clinicPage__mobile {
  display: none;
}

.clinicPage__Center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 70vh;
  width: 70vw;
  margin-bottom: 10vh;
  border-radius: 10px;
  background-color: #fefefe;
  margin-top: 170px;
}

.clinicPage__Left {
  width: 40%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.clinicPage__Left-highlightButton {
  height: calc(60vh / 7);
  width: calc(60vw * 0.3 * 0.8);
  border: none;
  font-family: opensans-med;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  /* identical to box height */
  background: #32817d;
  border-radius: 10px;
  text-align: center;
  color: white;
}

.clinicPage__Left-highlightButton:focus {
  outline: none;
}

.clinicPage__Left-highlightButton:hover {
  color: black;
}

.clinicPage__Left-normalButton {
  height: calc(60vh / 7);
  width: calc(60vw * 0.3 * 0.8);
  border: none;
  font-family: opensans-med;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  /* identical to box height */
  text-align: center;
  color: #32817d;
  background-color: white;
}

.clinicPage__Left-normalButton:focus {
  outline: none;
}

.clinicPage__Left-normalButton:hover {
  color: black;
}

.clinicPage__Right {
  width: 70%;
}

@media only screen and (min-width: 768px) and (max-width: 1300px) {
  .clinicPage__Center {
    width: 80vw;
  }
}

@media only screen and (max-width: 767px) {
  .clinicPage__Center {
    width: 100vw;
    height: 80%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-top: 170px;
  }
  .clinicPage__mobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 5px 20px;
  }
  .clinicPage__mobile__shadow {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    z-index: 3;
    background-color: rgba(0, 0, 0, 0.8);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .clinicPage__mobile__shadow-inner {
    height: 90vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .clinicPage__mobile__shadow-inner button {
    margin: 20px 0;
    width: 70vw;
    height: 50px;
    border-radius: 10px;
    background-color: white;
    -webkit-transition: 1s all ease-in-out;
    transition: 1s all ease-in-out;
  }
  .clinicPage__mobile__shadow-inner img {
    margin-left: auto;
    display: block;
    width: 30px;
  }
  .clinicPage__Left {
    display: none;
  }
  .clinicPage__Right {
    width: 100%;
  }
}

.clinicServiceDiv {
  padding: 20px 30px 0 30px;
  height: 70vh;
  overflow-x: hidden;
  overflow-x: auto;
  text-align: justify;
}

.clinicServiceDiv-header {
  text-transform: capitalize;
  font-weight: 900;
  font-size: 40px;
}

.clinicServiceDiv-section {
  font-family: opensans-reg;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
}

.clinicServiceDiv-ul li {
  font-family: opensans-reg;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 30px;
  margin: 10px 0;
}

@media only screen and (max-width: 767px) {
  .clinicServiceDiv-header {
    text-align: center;
  }
  .clinicServiceDiv-section {
    margin: 10px 0;
    line-height: 25px;
    font-size: 20px;
  }
  .clinicServiceDiv ul {
    padding: 0;
  }
  .clinicServiceDiv ul li {
    margin: 10px 0;
    font-size: 18px;
  }
}

.clinicDoctorDiv {
  padding: 20px 30px 0 30px;
}

.clinicDoctorDiv-textOverDiv {
  overflow-x: hidden;
  overflow-x: auto;
  text-align: justify;
  height: 55vh;
}

.clinicDoctorDiv-header {
  text-transform: capitalize;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 30px;
}

.clinicDoctorDiv__section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 40px 0 0;
  margin: 15px 0;
}

.clinicDoctorDiv__section-header {
  font-family: opensans-bold;
  font-style: normal;
  font-weight: normal;
  font-size: 27px;
  line-height: 30px;
  margin: 10px 0 20px 0;
}

.clinicDoctorDiv__section__Div-img {
  height: 120px;
  width: 120px;
  margin-right: 20px;
}

.clinicDoctorDiv__section__Div section {
  text-indent: 20px;
  font-family: opensans-reg;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
}

.clinicDoctorDiv__section__Div section h4 {
  font-size: 20px;
}

.clinicDoctorMaleDiv__section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.clinicDoctorMaleDiv__section-header {
  font-size: 30px;
  font-weight: 600;
  margin: 10px 0 20px 0;
}

.clinicDoctorMaleDiv__section__Div-img {
  margin: 0 10px;
  height: 120px;
  width: 120px;
}

.clinicDoctorMaleDiv__section__Div section {
  font-size: 20px;
  text-indent: 20px;
  font-weight: 200;
}

@media only screen and (max-width: 767px) {
  .clinicDoctorDiv {
    padding: 0;
  }
  .clinicDoctorDiv-header {
    text-align: center;
    margin: 20px 0 0 0;
  }
  .clinicDoctorDiv__section {
    padding: 10px 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .clinicDoctorDiv__section__Div-img {
    height: 70px;
    width: 70px;
    margin: 20px 0;
  }
}

.clinicWalkInDiv {
  overflow-x: hidden;
  overflow-x: auto;
  text-align: justify;
  height: 70vh;
  padding: 20px 30px 0 30px;
}

.clinicWalkInDiv-header {
  text-transform: capitalize;
  font-family: opensans-bold;
  font-style: normal;
  font-weight: normal;
  font-size: 35px;
  line-height: 30px;
}

.clinicWalkInDiv__textDiv2 {
  font-family: opensans-reg;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
}

.clinicWalkInDiv__textDiv h3 {
  font-family: opensans-med;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 30px;
  margin: 30px 0 50px 0;
}

.clinicWalkInDiv__textDiv h4 {
  margin: 20px 0;
  font-family: opensans-med;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 30px;
}

.clinicWalkInDiv__textDiv h5 {
  font-family: opensans-reg;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  text-indent: 20px;
}

.clinicWalkInDiv__textDiv ol li {
  font-size: 20px;
  margin: 10px 0;
}

.clinicWalkInDiv__textDiv ol li a {
  margin-left: 10px;
}

.clinicWalkInDiv-policyHeader {
  font-family: opensans-bold;
  font-style: normal;
  font-weight: normal;
  font-size: 35px;
  line-height: 30px;
  margin: 40px 0 0 0;
}

.faq-header {
  margin-bottom: 10px;
}
.clinicWalkInDiv-policyDiv h4 {
  font-weight: 550;
  font-size: 30px;
}

.clinicWalkInDiv-policyDiv h5 {
  font-weight: 100;
  font-size: 20px;
  text-indent: 20px;
  margin: 20px 0;
}

.clinicWalkInDiv-policyDiv ol li {
  font-size: 20px;
  margin: 10px 0;
}

.clinicWalkInDiv-walkInHeader {
  text-transform: capitalize;
  font-family: opensans-bold;
  font-style: normal;
  font-weight: normal;
  font-size: 35px;
  line-height: 30px;
}

.clinicWalkInDiv2 {
  overflow-x: hidden;
  overflow-x: auto;
  text-align: justify;
  height: 70vh;
  padding: 20px 30px 0 30px;
  font-family: "Times New Roman", Times, serif;
}

@media only screen and (max-width: 767px) {
  .clinicWalkInDiv__textDiv h3 {
    font-size: 20px;
    text-indent: 20px;
  }
  .clinicWalkInDiv__textDiv h4 {
    font-size: 20px;
    text-indent: 20px;
  }
  .clinicWalkInDiv__textDiv h5 {
    font-size: 18px;
  }
  .clinicWalkInDiv__textDiv ol {
    padding: 0;
  }
  .clinicWalkInDiv-policyHeader {
    text-align: center;
    font-size: 30px;
  }
  .clinicWalkInDiv-policyDiv h4 {
    text-align: start;
    font-size: 25px;
  }
  .clinicWalkInDiv-policyDiv h5 {
    font-size: 20px;
  }
  .clinicWalkInDiv-policyDiv ol li {
    font-size: 18px;
  }
}

.clinicFeeGuide {
  font-family: opensans-reg;
  overflow-x: hidden;
  overflow-x: auto;
  text-align: justify;
  height: 70vh;
  padding: 20px 30px 0 30px;
}

.clinicFeeGuide-header {
  margin-bottom: 30px;
  text-transform: capitalize;
  text-transform: capitalize;
  font-family: opensans-bold;
  font-style: normal;
  font-weight: normal;
  font-size: 35px;
  line-height: 30px;
}

.clinicFeeGuide-textDiv {
  text-transform: capitalize;
  font-family: opensans-reg;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
}

.clinicFeeGuide-explain {
  width: 90%;
  margin-bottom: 30px;
  font-family: opensans-reg;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
}

@media only screen and (max-width: 767px) {
  .clinicFeeGuide-header {
    font-size: 25px;
    margin-bottom: 15px;
  }
  .clinicFeeGuide-textDiv {
    font-size: 20px;
  }
}

.schedulePage {
  width: 70%;
  margin: 0 auto;
  padding: 8rem 0;
}
.schedulePage h2 {
  font-weight: 700;
}

.schedulePage .table-container {
  margin-top: 2rem;
}

.schedulePage .virtualTable {
  margin-bottom: 4rem;
}

.schedulePage .virtualTable .first-block,
.schedulePage .inClinicTable .second-block {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.doctor-icon-container {
  width: 150px;
  height: 150px;
}

.first-block .doctor-icon,
.second-block .doctor-icon {
  width: 100%;
  border-style: none;
  border-radius: 75px;
}
.schedulePage .doctorTable {
  width: 100%;
}
@media (max-width: 768px) {
  .schedulePage {
    width: 95%;
    padding: 5.5rem 0;
  }

  .schedulePage h2 {
    font-size: 1.5rem;
    font-weight: 600;
  }

  .schedulePage .virtualTable .first-block,
  .schedulePage .inClinicTable .second-block {
    gap: 1rem;
  }

  .doctor-icon-container {
    width: 80px;
    height: 80px;
  }

  .first-block .doctor-icon,
  .second-block .doctor-icon {
    border-radius: 50px;
  }

  .schedulePage .table-container {
    overflow-x: auto;
    margin-top: 1rem;
  }

  .schedulePage .doctorTable {
    width: auto;
    min-width: 100%;
    max-width: none;
  }
}
.paymentPage {
  padding: 100px 5vw 80px 5vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
}

.paymentPage__price {
  padding-bottom: 30px;
}

.paymentPage__price input[type="radio"] {
  margin-right: 10px;
}

.radio {
  display: block;
  cursor: pointer;
  user-select: none;
  text-align: left;
  & + .radio {
    margin-top: 7px;
  }
  input {
    display: none;
    & + span {
      display: inline-block;
      position: relative;
      padding-left: 20px;
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0px;
        left: 0px;
        border-radius: 50%;
        margin-right: 5px;
        width: 16px;
        height: 16px;
        border: 1px solid #ccc;
        background: #fff;
      }
      &:after {
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        background: #222;
        position: absolute;
        border-radius: 50%;
        top: 3px;
        left: 3px;
        opacity: 0;
        transform: scale(0, 0);
        transition: all 0.2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
      }
    }
    &:checked + span:after {
      opacity: 1;
      transform: scale(1, 1);
    }
  }
}

.paymentPage h2 {
  text-align: center;
}

.contactPage {
  padding: 100px 5vw 0 5vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
}
.resourcePage {
  width: 70%;
  margin: 0 auto;
  padding: 10rem 0;
}
.resourcePage-container {
  /* margin-top: 10px;
  margin-bottom: 16px; */
}
.contactPage__location1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 30px 0;
  border-radius: 10px;
  width: 80vw;
}

.contactPage__location1__infoDiv {
  width: 30vw;
  padding-left: 5vw;
  width: 50%;
}

.contactPage__location1__infoDiv h2 {
  margin: 0;
  margin-bottom: 20px;
  font-family: opensans-bold;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 22px;
}

.contactPage__location1__infoDiv h3 {
  font-family: opensans-med;
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 22px;
}

.contactPage__location1__infoDiv-hour h3 {
  margin: 20px 0 10px 0;
}

.contactPage__location1__infoDiv-hour h4 {
  font-family: opensans-reg;
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 22px;
}

.contactPage__location1__infoDiv-address {
  margin-top: 20px;
}

.contactPage__location1__infoDiv-address h3 {
  margin-bottom: 10px;
}

.contactPage__location1__infoDiv-address h4 {
  margin: 10px 0 0 0;
  font-weight: 300;
}

.contactPage__location1__infoDiv-address a {
  font-weight: 550;
  font-size: 18px;
  margin: 10px 0 0 0;
  color: black;
  text-decoration: none;
}

.contactPage__location2 {
  width: 80vw;
  padding: 50px 0 50px 90px;
}

@media only screen and (min-width: 1500px) {
  .contactPage {
    height: 100vh;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1300px) {
  .contactPage__location1 {
    width: 85vw;
  }
}

@media only screen and (max-width: 767px) {
  .contactPage__location1 {
    width: 90vw;
  }
  .contactPage__location1__infoDiv {
    width: 100%;
  }
  /* .contactPage__location1-img {
    display: none;
  } */
}

.faq {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  width: 100vw;
}

.faq__centerDiv {
  margin: 120px 0 50px 0;
}

.faq__centerDiv-header {
  margin-bottom: 10px;
  margin-top: 30px;
}

.faq__centerDiv__questDiv {
  height: 200px;
  width: 70vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow-x: auto;
  height: 70vh;
}

.faq__centerDiv__questDiv div {
  font-size: 20px;
  padding: 10px 20px;
  font-weight: 600;
  font-family: opensans-med;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  /* identical to box height */
}

.faq__centerDiv__questDiv div:hover {
  cursor: pointer;
}

.faq__centerDiv__questDiv h4 {
  background-color: white;
  margin: 0;
  padding: 20px;
  font-family: opensans-reg;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
}

.faq__centerDiv__questDiv h4 img {
  margin-right: 10px;
}

@media only screen and (max-width: 767px) {
  .faq__centerDiv-header {
    padding: 10px 0 0 30px;
  }
  .faq__centerDiv__questDiv {
    width: 95vw;
  }
}

.epay {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.epay__center {
  border-radius: 10px;
  padding: 50px;
  margin-top: 120px;
  width: 60vw;
}

.epay__center-header {
  text-transform: capitalize;
  font-family: opensans-bold;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
  color: #32817d;
  margin: 0 0 50px 0;
}

.epay__textDiv h4 {
  margin: 10px 0;
  padding-left: 2vw;
  font-family: opensans-reg;
  font-style: normal;
  font-size: 25px;
  line-height: 35px;
}

@media only screen and (min-width: 768px) and (max-width: 1300px) {
  .epay__center {
    margin: 120px 0 50px 0;
    width: 80vw;
  }
  .epay__center-header {
    margin-bottom: 20px;
  }
  .epay__center h4 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .epay {
    height: 100vh;
  }
  .epay__center {
    width: 85vw;
    margin: 180px 0 100px 0;
  }
  .epay__center-header {
    margin-bottom: 17px;
  }
  .epay__center h4 {
    font-size: 18px;
    margin: 20px 0;
  }
}

.guardme {
  padding: 100px 0;
  width: 100vw;
}

.guardme-header {
  width: 100vw;
  text-align: center;
  height: 50px;
  margin-top: 100px;
  font-family: opensans-bold;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
  color: #32817d;
}

.guardme-header1 {
  margin: 20px 0 50px 0;
  text-align: center;
  font-family: opensans-bold;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
  color: #32817d;
}

.guardme-footer {
  text-decoration: none;
  width: 150px;
  margin: 0 auto;
  display: block;
  border-radius: 10px;
  text-align: center;
  padding: 10px 0;
  color: white;
  font-family: opensans-reg;
  font-style: normal;
  font-size: 25px;
  line-height: 35px;
  background-color: #32817d;
}

.guardme__center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.guardme__center-img {
  height: 200px;
  width: 200px;
}

.guardme__center__leftRightDiv {
  width: 30vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.guardme__center__leftRightDiv-missingDiv {
  height: 70px;
}

.guardme__center__leftRightDiv h3 {
  margin: 50px 0;
  font-family: opensans-bold;
  font-style: normal;
  font-size: 25px;
  line-height: 35px;
}

.guardme__center__leftRightDiv h4 {
  margin: 20px 0;
  font-family: opensans-reg;
  font-style: normal;
  font-size: 25px;
  line-height: 35px;
}

.guardme__center__leftRightDiv h5 {
  margin: 20px 0;
  font-family: opensans-reg;
  font-style: normal;
  font-size: 25px;
  line-height: 35px;
}

.guardme__center__leftRightDiv ul li {
  margin: 15px 0;
  font-family: opensans-reg;
  font-style: normal;
  font-size: 25px;
  line-height: 35px;
}

@media only screen and (max-width: 767px) {
  .guardme-header1 {
    margin: 0 50px 30px;
  }
  .guardme__center-img {
    height: 100px;
    width: 100px;
  }
  .guardme__center__leftRightDiv-missingDiv {
    height: 200px;
  }
  .guardme__center__leftRightDiv h3 {
    height: 100px;
  }
}

.private {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
}

.private-header {
  margin-bottom: 20px;
  font-family: opensans-bold;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
  color: #32817d;
}

.private__textDiv {
  width: 70vw;
  border-radius: 10px;
  padding: 20px;
  margin: 120px 0 50px 0;
}

.private__textDiv h4 {
  font-family: opensans-med;
  font-style: normal;
  font-size: 25px;
  line-height: 35px;
}

.private__textDiv ul li {
  font-family: opensans-reg;
  font-style: normal;
  font-size: 25px;
  line-height: 35px;
}

.private__textDiv1 {
  padding: 20px;
  margin: 120px 0 50px 0;
}

.private__textDiv1 h4 {
  font-size: 25px;
  margin: 10px 0;
}

.private__textDiv1 ul li {
  font-size: 20px;
  margin: 10px 0;
}

.private__textDiv1 ol li {
  margin: 10px 0;
}

.private1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 1300px) {
  .private {
    height: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .private {
    height: 100%;
  }
  .private__textDiv {
    width: 85vw;
  }
  .private__textDiv h4 {
    font-size: 20px;
  }
  .private__textDiv ul li {
    font-size: 18px;
  }
  .private__textDiv1 h4 {
    font-size: 20px;
  }
  .private__textDiv1 ul li {
    font-size: 18px;
  }
}

@media only screen and (min-width: 1500px) {
  .private {
    height: 100vh;
  }
}

.uninsured {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.uninsured-basedOn {
  width: 60vw;
  margin-bottom: 30px;
  font-family: opensans-med;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 45px;
}

.uninsured-header {
  margin: 120px 0 50px 0;
  font-family: opensans-bold;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
  color: #32817d;
}

.uninsured__table {
  border-spacing: 0;
  width: 70vw;
  border: 1px solid #c8c8c8;
  border-radius: 10px;
  margin-bottom: 50px;
}

.uninsured__table-row {
  height: 50px;
}

.uninsured__table-price {
  border: 1px solid #c8c8c8;
  padding: 5px;
  font-family: opensans-reg;
  font-style: normal;
  font-size: 20px;
  line-height: 35px;
}

.uninsured__table-name {
  border: 1px solid #c8c8c8;
  padding: 5px;
  font-family: opensans-reg;
  font-style: normal;
  font-size: 20px;
  line-height: 35px;
}

@media only screen and (max-width: 767px) {
  .uninsured-basedOn {
    width: 80vw;
  }
  .uninsured__table {
    width: 95vw;
  }
}

.testResult {
  width: 100vw;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.testResult__center {
  width: 70vw;
  height: 100%;
  padding: 30px;
  margin: 120px 0 50px 0;
}

.testResult__center-header {
  text-transform: capitalize;
  font-family: opensans-bold;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
  color: #32817d;
}

.testResult__center div {
  margin: 20px 0;
  font-family: opensans-reg;
  font-style: normal;
  font-size: 25px;
  line-height: 35px;
}

.testResult__center div h4 {
  font-family: opensans-reg;
  font-style: normal;
  font-size: 25px;
  line-height: 35px;
}

@media only screen and (min-width: 768px) and (max-width: 1300px) {
  .testResult {
    height: 100%;
  }
  .testResult__center {
    margin: 120px 0 50px 0;
  }
}

@media only screen and (max-width: 767px) {
  .testResult {
    height: 100%;
  }
  .testResult__center {
    margin: 100px 0 50px 0;
    width: 90vw;
  }
  .testResult__center-header {
    font-size: 25px;
  }
  .testResult div {
    font-size: 20px;
  }
  .testResult div h4 {
    font-size: 25px;
    font-weight: 550;
    margin-bottom: 20px;
  }
}

.referral {
  width: 100vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.referral__center {
  width: 70vw;
  margin: 120px 0 50px 0;
  padding: 30px;
  height: 100%;
}

.referral__center-header {
  text-transform: capitalize;
  font-family: opensans-bold;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
  color: #32817d;
  margin-bottom: 20px;
}

.referral__center ol li {
  margin: 10px 0;
  font-family: opensans-reg;
  font-style: normal;
  font-size: 25px;
  line-height: 35px;
}

.referral__center div {
  font-family: opensans-reg;
  font-style: normal;
  font-size: 22px;
  line-height: 35px;
}

@media only screen and (min-width: 1500px) {
  .referral {
    height: 100vh;
  }
  .referral__center {
    height: 80%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1300px) {
  .referral__center {
    width: 80vw;
  }
}

@media only screen and (max-width: 767px) {
  .referral__center {
    width: 90vw;
  }
  .referral__center-header {
    font-size: 23px;
  }
  .referral__center ol li {
    font-size: 20px;
  }
  .referral__center div {
    font-size: 18px;
  }
}

.chart {
  width: 100vw;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.chart__center {
  width: 60vw;
  padding: 50px;
  margin-top: 120px;
}

.chart__center-header {
  margin-bottom: 20px;
  font-family: opensans-bold;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
  color: #32817d;
}

.chart__center div {
  padding-left: 30px;
  font-family: opensans-reg;
  font-style: normal;
  font-size: 25px;
  line-height: 35px;
}

@media only screen and (min-width: 768px) and (max-width: 1300px) {
  .chart__center {
    padding: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .chart__center-header {
    font-size: 30px;
  }
  .chart__center div {
    font-size: 20px;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .chart {
    height: 100vh;
  }
  .chart__center {
    padding: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 90vw;
    margin: 130px 0 50px 0;
  }
  .chart__center-header {
    font-size: 30px;
  }
  .chart__center div {
    font-size: 20px;
    width: 100%;
    margin: 5px 0;
    padding-left: 0;
  }
}

.insured {
  width: 100vw;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.insured__center {
  width: 70vw;
  border-radius: 10px;
  padding: 20px 20px 10px 20px;
  margin-top: 120px;
}

.insured__center h2 {
  text-transform: capitalize;
  font-family: opensans-bold;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
  color: #32817d;
}

.insured__center div {
  margin: 20px 0;
  font-family: opensans-reg;
  font-style: normal;
  font-size: 25px;
  line-height: 35px;
}

.insured__center a {
  margin-left: 5px;
}

@media only screen and (min-width: 768px) and (max-width: 1300px) {
  .insured__center {
    padding: 30px 20px 20px 30px;
    margin-top: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .insured {
    height: 100%;
  }
  .insured__center {
    padding: 30px 20px 20px 30px;
    margin: 200px 0 70px 0;
    height: 100%;
    width: 90vw;
  }
}

.renewal {
  width: 100vw;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.renewal__center {
  width: 70vw;
  padding: 30px;
  margin-top: 50px;
}

.renewal__center-header {
  text-transform: capitalize;
  font-family: opensans-bold;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
  color: #32817d;
}

.renewal__center div {
  margin: 20px 0;
  font-family: opensans-reg;
  font-style: normal;
  font-size: 25px;
  line-height: 35px;
}

@media only screen and (min-width: 768px) and (max-width: 1300px) {
  .renewal__center {
    height: 70vh;
  }
  .renewal__center-header {
    font-size: 30px;
  }
  .renewal__center div {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .renewal {
    height: 100%;
  }
  .renewal__center {
    margin: 190px 0 50px 0;
    height: 100%;
    width: 90vw;
  }
  .renewal__center-header {
    font-size: 25px;
  }
  .renewal__center div {
    font-size: 19px;
  }
}

.virtual {
  padding: 20px 30px 0 30px;
}

.virtual__bigTextDiv {
  overflow-x: hidden;
  overflow-x: auto;
  text-align: justify;
  height: 55vh;
}

.virtual__bigTextDiv-header {
  text-transform: capitalize;
  text-transform: capitalize;
  font-family: opensans-bold;
  font-style: normal;
  font-weight: normal;
  font-size: 35px;
  line-height: 30px;
  margin-bottom: 30px;
}

.virtual__bigTextDiv-firstDiv {
  padding: 0 30px 0 0;
  text-indent: 20px;
}

.virtual__bigTextDiv-firstDiv h4 {
  text-transform: capitalize;
  font-family: opensans-med;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
}

.virtual__bigTextDiv-firstDiv section {
  text-indent: 0;
  text-transform: capitalize;
  font-family: opensans-reg;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
}

.virtual__bigTextDiv-firstDiv ol li {
  text-transform: capitalize;
  font-family: opensans-reg;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 30px;
}

.resource-atag {
  padding-left: 1.2rem;
}

@media only screen and (max-width: 767px) {
  .virtual {
    height: 70vh;
  }
  .virtual h3 {
    font-size: 25px;
  }
}

.generalCenter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.generalCenter-center {
  padding: 50px;
  width: 60vw;
  margin-top: 150px;
}

.generalCenter-center p {
  font-family: opensans-bold;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
  color: #32817d;
}

.generalCenter-center h3 {
  font-family: opensans-reg;
  font-style: normal;
  font-size: 20px;
  line-height: 30px;
}

.generalCenter-center h4 {
  font-family: opensans-reg;
  font-style: normal;
  font-size: 20px;
  line-height: 30px;
}

.generalCenter-center section {
  font-family: opensans-med;
  font-style: normal;
  font-size: 23px;
  line-height: 30px;
}

.generalCenter-center section a {
  color: #32817d;
  text-decoration: none;
}

.generalCenter-highlight {
  font-weight: 800;
  font-size: 24px;
}

.generalCenter-highlight1 {
  font-weight: 900;
  font-size: 27px;
}

.newFamilyPatient {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.newFamilyPatient-center {
  padding: 50px;
  width: 70vw;
  margin-top: 120px;
}

.newFamilyPatient-center section {
  font-family: opensans-bold;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
  color: #32817d;
}

.newFamilyPatient-center h3 {
  font-family: opensans-reg;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 45px;
}

.newFamilyPatient-center h4 {
  font-family: opensans-reg;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 45px;
}

.newFamilyPatient-center ol li {
  font-family: opensans-reg;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 45px;
  color: #32817d;
}

.newFamilyPatient-center ol li a {
  font-family: opensans-reg;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 45px;
  color: #32817d;
}

.generalInsideDiv {
  padding: 50px;
  width: 70vw;
}

.generalInsideDiv h2 {
  font-family: opensans-bold;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
  color: #32817d;
}

.generalInsideDiv h3 {
  font-family: opensans-reg;
  font-style: normal;
  font-size: 25px;
  line-height: 35px;
}

.generalInsideDiv h3 a {
  color: #32817d;
  text-decoration: none;
}

.virtualBooking {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.virtualBooking-center {
  padding: 50px;
  width: 70vw;
  margin-top: 120px;
}

.virtualBooking-center h2 {
  font-family: opensans-bold;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
  color: #32817d;
}

.virtualBooking-center h3 {
  font-family: opensans-reg;
  font-style: normal;
  font-size: 25px;
  line-height: 35px;
}

.backButton {
  position: relative;
  top: 75%;
  padding-bottom: 30px; /*change back button position from 40% to 75% and put padding bottom due to new information needed*/
  color: #32817d;
  text-decoration: none;
}

.newsId {
  width: 100vw;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.newsId__center {
  padding: 180px 50px 0 50px;
}

.newsId__center div {
  padding: 30px 0;
  text-indent: 20px;
  font-size: 23px;
}

@media only screen and (max-width: 767px) {
  .newFamilyPatient-center {
    width: 95vw;
  }
  .generalCenter {
    height: 100%;
  }
  .generalCenter-center {
    width: 95vw;
    margin-top: 100px;
  }
  .generalCenter-center h4 {
    width: 120px;
  }
  .bookingVirtual {
    height: 100vh;
  }
  .backButton {
    position: absolute;
    top: 65px;
    right: 10px;
  }
  .backButton img {
    height: 30px;
    width: 30px;
  }
}

.bookingIns,
.BookingInstruction {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100vh;
}

.bookingIns-virtualDiv,
.BookingInstruction-virtualDiv {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  font-family: opensans-bold;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
  color: #32817d;
  text-align: center;
}

.bookingIns-video {
  width: 600px;
  height: 300px;
  border: 1px solid black;
  margin: 20px 0;
}

@media only screen and (max-width: 767px) {
  .bookingIns-video {
    width: 300px;
    height: 200px;
    border: 1px solid black;
  }
}

.allNews {
  width: 100vw;
  height: 100vh;
}

.allNews__emptyDiv {
  height: 140px;
}

.allNews__banner {
  height: 500px;
}

.allNews__banner__content {
  height: 500px;
}

.allNews__banner__content-1 {
  background-image: url("../assets/NewsBanner/rsz_jvon-banner_1440x576.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  width: 100vw;
}

.allNews__banner__content-2 {
  background-image: url("../assets/NewsBanner/WechatIMG.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  width: 100vw;
}

.allNewsWork {
  width: 100vw;
  height: 100vh;
}

.allNewsWork-bannerDiv {
  height: 500px;
  background-image: url("../assets/NewsBanner/rsz_jvon-banner_1440x576.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.allNewsWork__Div {
  margin: 20px 0;
  padding: 20px;
}

.allNewsWork__Div h3 {
  font-weight: 800;
  color: #808e94;
}

.allNewsWork__Div h4 {
  text-indent: 20px;
  font-weight: 300;
  margin-top: 20px;
  padding-bottom: 25px;
  border-bottom: 1px solid #808e94;
}

@media only screen and (max-width: 767px) {
  .allNews__emptyDiv {
    height: 70px;
  }
  .allNews__banner {
    height: 200px;
  }
  .allNews__banner__content {
    height: 200px;
  }
  .allNews__banner__content-1 {
    background-image: url(../assets/NewsBanner/thumbnail_Jvon-banner_450x220.png);
  }
  .allNews__banner__content-2 {
    background-image: url("../assets/NewsBanner/WechatIMG.jpeg");
  }
  .allNewsWork-bannerDiv {
    height: 200px;
    background-image: url(../assets/NewsBanner/thumbnail_Jvon-banner_450x220.png);
  }
}

.adminControl {
  width: 100vw;
  height: 100%;
}

.adminControl__englishDiv {
  padding: 150px 20px 50px 20px;
}

.adminControl__englishDiv-header {
  text-transform: capitalize;
  margin-bottom: 20px;
}

.adminControl__englishDiv-Inner {
  width: 70vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.adminControl__englishDiv-Inner textarea {
  margin: 20px 0;
  border-radius: 10px;
  padding-left: 5px;
  font-size: 20px;
  border: 2px solid black;
}

.adminControl__englishDiv-Inner textarea:focus {
  outline: none;
  border: 2px solid skyblue;
}

.adminControl__englishDiv-Inner input {
  border-radius: 10px;
  height: 50px;
  padding-left: 5px;
  font-size: 20px;
}

.adminControl__englishDiv-Inner input:focus {
  outline: none;
  border: 2px solid skyblue;
}

.adminControl__chineseDiv {
  padding: 0 20px 50px 20px;
}

.adminControl__chineseDiv-header {
  text-transform: capitalize;
}

.adminControl__chineseDiv-Inner {
  width: 70vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.adminControl__chineseDiv-Inner textarea {
  margin: 20px 0;
  border-radius: 10px;
  padding-left: 5px;
  font-size: 20px;
  border: 2px solid black;
}

.adminControl__chineseDiv-Inner textarea:focus {
  outline: none;
  border: 2px solid skyblue;
}

.adminControl__chineseDiv-Inner input {
  border-radius: 10px;
  height: 50px;
  padding-left: 5px;
  font-size: 20px;
}

.adminControl__chineseDiv-Inner input:focus {
  outline: none;
  border: 2px solid skyblue;
}

.lotusNews {
  width: 100vw;
  height: 100%;
}

.lotusNews__center {
  padding: 170px 20px 0 20px;
}

.lotusNews__center-header {
  text-align: center;
}

.lotusNews__center-img {
  height: 500px;
  width: 70vw;
  margin: 30px auto;
  display: block;
}

.lotusNews__center-do {
  margin-left: 30px;
}

.lotusNews__center__textDiv h4 {
  font-family: opensans-reg;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
}

.lotusNews__center ul li {
  font-family: opensans-reg;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 30px;
}

.lotusNews__center-bottomText {
  font-family: opensans-med;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
}

.lotusNews__center-bottomDiv h4 {
  font-family: opensans-med;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  margin-bottom: 100px;
}

html {
  margin: 0;
  padding: 0;
  width: 100vw;
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100vw;
  overflow-x: hidden;
}

/* .boldFont {
  font-family: opensans-bold;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 30px;
}

.regFont {
  font-family: opensans-reg;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
} */
