.clinicWalkInDiv {
	overflow-x: hidden;
	overflow-x: auto;
	text-align: justify;
	// font-family: "Times New Roman", Times, serif;

	height: 70vh;
	padding: 20px 30px 0 30px;
	&-header {
		text-transform: capitalize;
		font-family: quicksand-bold;
		font-style: normal;
		font-weight: normal;
		font-size: 35px;
		line-height: 30px;
	}
	&__textDiv2 {
		font-family: quicksand-reg;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		line-height: 30px;
	}
	&__textDiv {
		& h3 {
			font-family: quicksand-med;
			font-style: normal;
			font-weight: normal;
			font-size: 26px;
			line-height: 30px;
			margin: 30px 0 50px 0;
		}
		& h4 {
			margin: 20px 0;
			font-family: quicksand-med;
			font-style: normal;
			font-weight: normal;
			font-size: 26px;
			line-height: 30px;
		}
		& h5 {
			font-family: quicksand-reg;
			font-style: normal;
			font-weight: normal;
			font-size: 20px;
			line-height: 30px;
			text-indent: 20px;
		}
		& ol {
			& li {
				font-size: 20px;
				margin: 10px 0;
				& a {
					margin-left: 10px;
				}
			}
		}
	}
	&-policyHeader {
		font-family: quicksand-bold;
		font-style: normal;
		font-weight: normal;
		font-size: 35px;
		line-height: 30px;
		margin: 40px 0 0 0;
	}
	&-policyDiv {
		& h4 {
			font-weight: 550;
			font-size: 30px;
		}
		& h5 {
			font-weight: 100;
			font-size: 20px;
			text-indent: 20px;
			margin: 20px 0;
		}
		& ol {
			& li {
				font-size: 20px;
				margin: 10px 0;
			}
		}
	}
	&-walkInHeader {
		text-transform: capitalize;
		font-family: quicksand-bold;
		font-style: normal;
		font-weight: normal;
		font-size: 35px;
		line-height: 30px;
	}
}
.clinicWalkInDiv2 {
	overflow-x: hidden;
	overflow-x: auto;
	text-align: justify;
	height: 70vh;
	padding: 20px 30px 0 30px;
	font-family: "Times New Roman", Times, serif;
}

@include mobile {
	.clinicWalkInDiv {
		&__textDiv {
			& h3 {
				font-size: 20px;
				text-indent: 20px;
			}
			& h4 {
				font-size: 20px;
				text-indent: 20px;
			}
			& h5 {
				font-size: 18px;
				// font-weight: 550;
			}
			& ol {
				padding: 0;
			}
		}
		&-policyHeader {
			text-align: center;
			font-size: 30px;
		}
		&-policyDiv {
			& h4 {
				text-align: start;
				font-size: 25px;
			}
			& h5 {
				font-size: 20px;
			}
			& ol {
				& li {
					font-size: 18px;
				}
			}
		}
	}
}
