.navDiv {
	z-index: 2;
	background-color: white;
	position: fixed;
	width: 100vw;
	padding: 0 20px;
	height: 100px;
	top: 0;
	// border-bottom: 1px solid black;
	display: flex;
	align-items: center;

	&__mobile {
		display: none;
	}
	&__navBar {
		display: flex;
		width: 95%;
		// justify-content: space-between;
		align-items: center;
		& img {
			height: 70px;
		}
		&-title {
			margin-left: 25px;
			& h5 {
				font-family: quicksand-reg;
				font-style: normal;
				font-weight: normal;
				font-size: 22px;
				line-height: 30px;
			}
			& section {
				font-family: quicksand-reg;
				font-style: normal;
				font-weight: normal;
				font-size: 22px;
				line-height: 30px;
			}
			& img {
				height: 19px;
				width: 19px;
				margin-right: 10px;
			}
		}
		&__links {
			margin-left: 200px;
			font-family: quicksand-med;
			font-style: normal;
			font-weight: 500;
			font-size: 21px;
			line-height: 30px;
			&-link {
				color: black;
				text-decoration: none;
				margin: 0 20px;
			}
		}
		&-button {
			margin-left: auto;
			display: block;
			// width: 48px;
			border: none;
			background-color: white;
			color: #82cecc;
			font-family: quicksand-med;
			font-style: normal;
			font-weight: 500;
			font-size: 21px;
			line-height: 30px;
			&:focus {
				outline: none;
			}
		}
	}
}

@include tablet {
	.navDiv {
		height: 70px;
		padding: 0;
		&__mobilePopOut {
			display: flex;
			justify-content: space-between;
			height: 100vh;
			padding: 0 20px;
			&-button {
				width: 30vw;
				border: none;
				background-color: white;
				align-self: flex-start;
				margin-top: 20px;
				color: #82cecc;
				font-family: Quicksand-med;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 17px;
			}
			&__links {
				margin: 0;
				width: 60vw;
				display: flex;
				flex-direction: column;
				&-link {
					width: 100%;
					text-align: right;
					font-family: quicksand-med;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 17px;
					color: black;
					margin: 20px 0;
				}
			}
		}
		&__navBar {
			display: none;
		}

		&__mobile {
			display: flex;
			height: 70px;
			position: fixed;
			width: 100vw;
			align-items: center;
			padding: 15px;

			&-logo {
				height: 50px;
				width: 50px;
				margin-right: 10px;
			}
			&-menu {
				height: 20px;
				width: 50px;
				display: block;
				margin-left: auto;
			}
			& div {
				display: flex;
				flex-direction: column;
				justify-content: center;
				& img {
					height: 20px;
					width: 20px;
				}
				& section {
					margin-top: 10px;
				}
			}
			& h4 {
				font-size: 18px;
				margin: 0;
				text-align: center;
			}
			&-secondH4 {
				font-weight: 100;
			}
		}
	}
}
@include mobile {
	.navDiv {
		height: 70px;
		padding: 0;
		&__mobilePopOut {
			display: flex;
			justify-content: space-between;
			height: 100vh;
			padding: 0 20px;
			&-button {
				width: 30vw;
				border: none;
				background-color: white;
				align-self: flex-start;
				margin-top: 20px;
				color: #82cecc;
				font-family: Quicksand-med;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 17px;
			}
			&__links {
				margin: 0;
				width: 60vw;
				display: flex;
				flex-direction: column;
				&-link {
					width: 100%;
					text-align: right;
					font-family: quicksand-med;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 17px;
					color: black;
					margin: 20px 0;
				}
			}
		}
		&__navBar {
			display: none;
		}

		&__mobile {
			display: flex;
			height: 70px;
			position: fixed;
			width: 100vw;
			align-items: center;
			padding: 15px;

			&-logo {
				height: 50px;
				width: 50px;
				margin-right: 10px;
			}
			&-menu {
				height: 20px;
				width: 50px;
				display: block;
				margin-left: auto;
			}
			& div {
				display: flex;
				flex-direction: column;
				justify-content: center;
				& img {
					height: 20px;
					width: 20px;
				}
				& section {
					margin-top: 10px;
				}
			}
			& h4 {
				font-size: 18px;
				margin: 0;
				text-align: center;
			}
			&-secondH4 {
				font-weight: 100;
			}
		}
	}
}
